import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import VevolaModal from "components/modal/vevolaModal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { api } from "components/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    minWidth: 350,
    "& div": {
      width: "100%",
    },
  },
  buttons: {
    marginTop: "auto",
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  applybutton: {
    color: "white",
  },
}));

const EditUser = ({ user, setUser, refetch }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const open = user != null;
  if (!open) return <></>;

  const newuser = !user.hasOwnProperty("uid");

  let modaltitle = "Nieuwe gebruiker";
  if (!newuser) modaltitle = "Bewerk " + user.displayName;

  const handleChange = (e, n) => setUser({ ...user, [n]: e.target.value });

  const handleClose = () => {
    if (open) setUser(null);
  };

  const saveUser = async () => {
    let apicall;

    if (newuser)
      apicall = api({ method: "post", url: "/api/users/create", data: user });
    else
      apicall = api({ method: "post", url: "/api/users/update", data: user });

    apicall
      .then((res) => {
        enqueueSnackbar("Gebruiker bijgewerkt", {
          variant: "success",
        });
        refetch();
        setUser(null);
      })
      .catch((err) => {
        console.error("An api error occured");
        console.error(JSON.stringify(err));
        console.error(err.response.data.message);
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  return (
    <VevolaModal onClose={handleClose} onEnter={saveUser} title={modaltitle}>
      <div className={classes.root}>
        <form className={classes.form}>
          <TextField
            label="Naam"
            value={user.displayName}
            onChange={(e) => handleChange(e, "displayName")}
            margin="normal"
          />
          <TextField
            label="Email"
            value={user.email}
            onChange={(e) => handleChange(e, "email")}
            margin="normal"
          />
          {newuser ? (
            <TextField
              label="Wachtwoord"
              value={user.password}
              onChange={(e) => handleChange(e, "password")}
              margin="normal"
            />
          ) : (
            ""
          )}
        </form>
        <div className={classes.buttons}>
          <Button color="primary" onClick={handleClose}>
            Annuleren
          </Button>
          <Button
            className={classes.applybutton}
            color="primary"
            variant="contained"
            onClick={saveUser}
          >
            Opslaan
          </Button>
        </div>
      </div>
    </VevolaModal>
  );
};

export default EditUser;
