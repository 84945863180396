import React from "react";
import { api } from "components/api";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const VevolaAutocomplete = ({
  error = false,
  path,
  format,
  id,
  label,
  margin = "none",
  helperText = null,
  setValue,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await api({ url: path });

      const renters = await response.data;

      if (active) {
        setOptions(renters);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, path]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={setValue}
      getOptionSelected={(option, value) => option[id] === value[id]}
      getOptionLabel={(option) =>
        format(option) ? format(option) : "Onbekend"
      }
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={label}
          helperText={helperText}
          margin={margin}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export { VevolaAutocomplete };
