import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import VevolaProgress from "components/VevolaProgress";

import { ModalContext } from "./context";
import { useSnackbar } from "notistack";
import { TabPanel } from "components/tabs";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    margin: 0,
    padding: theme.spacing(1, 2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  closeWithIcon: {
    top: theme.spacing(1),
  },
});

const tabStyles = (theme) => ({
  appBar: {
    borderRadius: "4px 4px 0px 0px",
    flexDirection: "row",
  },
  tabsRoot: {
    flexGrow: 1,
  },
  slideContainer: {
    minHeight: 375,
    "&> div > div ": {
      overflow: "hidden !important",
    },
  },
  scroller: {
    "&> div > button:last-of-type": {
      marginLeft: "auto",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, icon, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {icon}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={clsx(classes.closeButton, { [classes.closeButton]: icon })}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogTabs = withStyles(tabStyles)((props) => {
  const { classes, onClose, value, handleChange, items, onlyEdit } = props;

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  return (
    <AppBar className={classes.appBar} position="static" color="default">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="standard"
        classes={{
          scroller: classes.scroller,
          root: classes.tabsRoot,
        }}
      >
        {items.map((item, index, arr) => (
          <Tab
            key={index}
            disabled={onlyEdit && arr.length - 1 !== index}
            label={item.label}
            wrapped={true}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </AppBar>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const VevolaModalDialog = ({
  loading,
  error,
  onClose,
  paperProps = {},
  onEnter,
  children,
}) => {
  const { dispatch } = React.useContext(ModalContext);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  if (loading)
    return (
      <Backdrop open={true} className={classes.backdrop}>
        <VevolaProgress />
      </Backdrop>
    );
  if (error) {
    enqueueSnackbar("Error: Couldnt create renter", {
      variant: "error",
    });
    console.error(error);
    return <></>;
  }

  return (
    <Dialog
      onClose={() => {
        dispatch({ type: "hide" });
        if (onClose) {
          onClose();
        }
      }}
      open={true}
      maxWidth={"md"}
      PaperProps={paperProps}
      onKeyPress={(e) => {
        if (onEnter && e.key === "Enter") onEnter();
      }}
    >
      {children}
    </Dialog>
  );
};

const VevolaModal = ({
  children,
  actions,
  onClose = null,
  onEnter,
  title,
  icon,
  loading = null,
  error = null,
}) => {
  const { dispatch } = React.useContext(ModalContext);

  return (
    <VevolaModalDialog
      loading={loading}
      error={error}
      onClose={onClose}
      onEnter={onEnter}
    >
      <DialogTitle
        id="customized-dialog-title"
        icon={icon}
        onClose={() => {
          dispatch({ type: "hide" });
          if (onClose) {
            onClose();
          }
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : <></>}
    </VevolaModalDialog>
  );
};

const PaperComponent = ({ dialogRef, width, height, ...other }) => {
  return (
    <div
      ref={dialogRef}
      style={{ minWidth: width, minHeight: height }}
      {...other}
    />
  );
};

export const TabbedVevolaModal = ({
  actions = null,
  onClose = null,
  onEnter = null,
  onlyEdit = false,
  loading = null,
  error = null,
  items,
}) => {
  const { dispatch } = React.useContext(ModalContext);
  const [value, setValue] = React.useState(onlyEdit ? items.length - 1 : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Listeners to make all tabs have the same size
  const [height, setHeight] = React.useState(null);
  const [width, setWidth] = React.useState(null);
  const [node, setNode] = React.useState(null);
  // Get the initial size of the modal
  const dialogRef = React.useCallback((node) => {
    if (node !== null) {
      setNode(node);
      setTimeout(() => {
        setWidth(node.getBoundingClientRect().width);
        setHeight(node.getBoundingClientRect().height);
      }, 200);
    }
  }, []);
  // Recalculate sizes if the window is being rescaled
  React.useLayoutEffect(() => {
    if (node) {
      const measuer = () => {
        setHeight(window.innerHeight - 64);
        setWidth(Math.min(window.innerWidth - 64, 960));
      };

      window.addEventListener("resize", measuer);

      return () => {
        window.removeEventListener("resize", measuer);
      };
    }
  }, [node]);

  return (
    <VevolaModalDialog
      loading={loading}
      error={error}
      onClose={onClose}
      onEnter={onEnter}
      paperProps={{
        component: PaperComponent,
        width: width,
        height: height,
        dialogRef: dialogRef,
      }}
    >
      <DialogTabs
        onClose={() => {
          dispatch({ type: "hide" });
          if (onClose) {
            onClose();
          }
        }}
        value={value}
        handleChange={handleChange}
        items={items}
        onlyEdit={onlyEdit}
      />
      <DialogContent dividers>
        {items.map((item, index) => (
          <TabPanel key={index} value={value} index={index}>
            {item.component}
          </TabPanel>
        ))}
      </DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : <></>}
    </VevolaModalDialog>
  );
};

export default VevolaModal;
