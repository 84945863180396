import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderRadius: "4px 4px 0px 0px",
  },
  slideContainer: {
    minHeight: 375,
    "&> div > div ": {
      overflow: "hidden !important",
    },
  },
  scroller: {
    "&> div": {
      justifyContent: "space-between",
    },
  },
}));

const TabComponent = ({ items, value, setValue, icon, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Paper {...props}>
      <AppBar className={classes.appBar} position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          classes={{
            scroller: classes.scroller,
          }}
        >
          {items.map((item, index) => (
            <Tab
              style={{
                maxWidth: index === 0 && icon ? 60 : null,
                minWidth: index === 0 && icon ? 60 : 160,
              }} // this is done to make the first tab small if icon is true to improve readability
              key={index}
              label={item.label}
              wrapped={true}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.slideContainer}
        disabled={true}
      >
        {items.map((item, index) => (
          <TabPanel
            key={index}
            value={value}
            index={index}
            dir={theme.direction}
          >
            {item.component}
          </TabPanel>
        ))}
      </SwipeableViews>
    </Paper>
  );
};

export const ContextVevolaTabs = ({
  items,
  icon = false,
  value,
  setValue,
  ...props
}) => {
  return (
    <TabComponent
      items={items}
      icon={icon}
      value={value}
      setValue={setValue}
      {...props}
    />
  );
};

export default function VevolaTabs({ items, icon = false, ...props }) {
  const [value, setValue] = React.useState(0);

  return (
    <TabComponent
      items={items}
      icon={icon}
      value={value}
      setValue={setValue}
      {...props}
    />
  );
}
