import React, { useState, useContext, useEffect } from "react";
import { produce } from "immer";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { FirebaseContext } from "components/firebase";
import { ModalProvider } from "components/modal/context";
import DrawerContainer from "./drawer";
import Toolbar from "dashboard/toolbar";
import Layout, { structure } from "./Layout";
import { Modal } from "components/modal/context";

import Error from "./pages/error";

const initialState = {
  isSidebarOpened: false,
  isPermanent: true,
};

export const StateContext = React.createContext({});

const Dashboard = () => {
  const [state, setState] = useState(initialState);

  const toggleSidebar = () => {
    const newState = produce(state, (draftstate) => {
      draftstate.isSidebarOpened = !state.isSidebarOpened;
    });
    setState(newState);
  };

  const togglePermanent = (value) => {
    const newState = produce(state, (draftstate) => {
      draftstate.isPermanent = value;
    });
    setState(newState);
  };

  const context = {
    state: state,
    actions: {
      toggleSidebar: toggleSidebar,
      togglePermanent: togglePermanent,
    },
  };

  const FBContext = useContext(FirebaseContext);

  const [claim, setClaim] = useState({});

  useEffect(() => {
    FBContext.doClaimCheck(structure, (data) => {
      setClaim(data);
    });
  }, [FBContext]);

  const RenderRoute = ({ component: Component, access, label, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (claim[access] || access === undefined) {
          return <Component {...props} />;
        } else {
          return <Error />;
        }
      }}
    />
  );

  return (
    <StateContext.Provider value={context}>
      <ModalProvider>
        <BrowserRouter>
          <React.Fragment>
            <Layout
              drawer={<DrawerContainer structure={structure} claim={claim} />}
              toolbar={<Toolbar structure={structure} />}
            >
              <Switch>
                {structure.map((page) => (
                  <RenderRoute
                    exact
                    key={page.id}
                    access={page.claim}
                    path={page.link}
                    label={page.label}
                    component={page.component}
                  />
                ))}
                <Redirect to="/overview" />
              </Switch>
            </Layout>
            <Modal />
          </React.Fragment>
        </BrowserRouter>
      </ModalProvider>
    </StateContext.Provider>
  );
};

export default Dashboard;
