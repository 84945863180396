const VevolaColors = {
  primary: "#EC884D", //vevola orange
  secondary: "#4CC7EC", //vevola blue
  warn: "#ef7963", //red
  info: "#fbde18", //yellow
  grey1: "#262b2c",
  grey2: "#74787b",
  grey3: "#ebebeb",
  grey4: "#383838",
  green: "#3f9f44",
};

export default VevolaColors;
