import React from "react";
import Chart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import { formatMoney } from "components/format";

// import BeatLoader from "react-spinners"

const TaxationChart = ({ data, loading }) => {
  const cached_packets = [
    "ING",
    "F. Spijkers / J Mekes",
    ...Array(4).fill("ABN/AMRO"),
  ];

  const chart_columns = loading
    ? cached_packets
    : data.map((p) => [p.bank_name, `LTV ${p.ltv}%`]);
  const bar_columns = [
    {
      name: "Taxatie",
      data: loading ? [] : data.map((x) => x["current_taxation"]),
    },
    {
      name: "Lening",
      data: loading ? [] : data.map((x) => x["current_balance"]),
    },
  ];

  const options = {
    chart: {
      id: "taxation-bar",
      stacked: false,
      toolbar: {
        show: false,
        offsetY: 24,
        offsetX: -15,
        tools: {
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true,
        },
        autoSelected: "pan",
      },
    },
    title: {
      text: "Overzicht",
      offsetY: 20,
      offsetX: 14,
      margin: 10,
      style: {
        fontSize: "1.25rem",
        // fontFamily: "Roboto",
        fontWeight: 500,
        color: "black",
      },
    },
    colors: ["#EC884D", "#4CC7EC", "#fbde18", "#ef7963"],
    yaxis: {
      title: {
        text: "Totaal (€)",
      },
      labels: {
        formatter: formatMoney,
      },
    },
    xaxis: { categories: chart_columns },
    tooltip: {
      enabled: false,
    },
    legend: {
      position: "bottom",
    },
    dataLabels: {
      formatter: formatMoney,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
  };

  return (
    <Paper style={{ marginBottom: 25, padding: 10 }}>
      <Chart options={options} series={bar_columns} type="bar" height="372" />
    </Paper>
  );
};

export default TaxationChart;
