import React, { useState } from "react";
import MaterialTable from "material-table";
import { api } from "components/api";
import { useSnackbar } from "notistack";
import produce from "immer";
import { useConfirm } from "material-ui-confirm";

import { ModalButton } from "components/form";
import useWindowResize from "components/hooks/useWindowResize";

import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Refresh from "@material-ui/icons/Refresh";
import Warning from "@material-ui/icons/Warning";
import Error from "@material-ui/icons/Error";
import CheckBox from "@material-ui/icons/CheckBox";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import { tableIcons } from "./../huurders/infoHuurders";
import VevolaTooltip from "components/VevolaTooltip";
import ContractGroup from "./contractGroup";
import { schiedam } from "./selectedPropertys";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: "-12px",
    color: "black",
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1) + "px",
  },
  green: {
    color: theme.palette.status.green,
  },
  warning: {
    color: theme.palette.status.info,
  },
  error: {
    color: theme.palette.status.danger,
  },
}));

const CheckBoxColumn = ({ rowData, value, handleCheck }) => {
  const classes = useStyles();
  const signed =
    rowData["contract_signed"] === false && value !== "contract_signed";
  return (
    <IconButton
      disabled={rowData[value] === null || !rowData.pending || signed}
      className={classes.icon}
      onClick={handleCheck}
    >
      {rowData[value] ? <CheckBox /> : <CheckBoxOutlineBlank />}
    </IconButton>
  );
};

const CheckInTable = ({ refetch, setRefetch }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { height } = useWindowResize();

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(null);

  // Hook to fetch data so that we can dynamically update and set the state if adjustments are made
  React.useEffect(() => {
    let active = true;
    if (!refetch) {
      return undefined;
    }

    (async () => {
      const response = await api({ url: "/api/properties/pending" });

      const data = await response.data;

      if (active) {
        //Check if the property is in Shiedam bereidverklaring Area
        data.forEach((element) => {
          const zipcodeSplit = element.POSTCODE
            ? element.POSTCODE.split(" ")
            : ["", ""];
          element.schiedamCheck =
            schiedam.findIndex((x) => {
              return (
                x.postcode === zipcodeSplit[0] &&
                x.postcode2.trim() === zipcodeSplit[1].toUpperCase() &&
                x.huisnummer.trim() === element.ADRNUMBER.toString()
              );
            }) > 0;
          element.rotterdamCheck = element.plaats === "Rotterdam";
        });

        setData(data);
        setRefetch(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [refetch, setRefetch]);

  const handleCheckClick = (rowData, field) => {
    // update the database throug API then change state on success
    const index = data.findIndex((data) => data.id === rowData.id);

    api({
      method: "post",
      url: "/api/rent/updateCheckField",
      data: { id: rowData.id, field: field, value: !data[index][field] },
    })
      .then((res) => {
        const newState = produce(data, (draftstate) => {
          if (index !== -1)
            draftstate[index][field] = !draftstate[index][field];
        });
        setData(newState);

        enqueueSnackbar("Status bijgewerkt", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Status bijwerken mislukt", {
          variant: "error",
        });
      });
  };

  const handleFinishClick = (rowData) => {
    //Finish the check-in procedure
    api({
      method: "post",
      url: "/api/rent/finishCheckIn",
      data: { id: rowData.id },
    })
      .then((res) => {
        const newState = produce(data, (draftstate) => {
          const index = data.findIndex((data) => data.id === rowData.id);
          if (index !== -1) draftstate[index].pending = false;
        });
        setData(newState);
        enqueueSnackbar("Check-in afgerond", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Check-in afronden mislukt", {
          variant: "error",
        });
      });
  };

  const handleCancelClick = (rowData) => {
    //Finish the check-in procedure
    confirm({
      title: "Annuleer check-in: " + rowData.ADRES,
      description: "Weet je zeker dat je de check-in wilt annuleren?",
    }).then(() => {
      api({
        method: "post",
        url: "/api/rent/cancelCheckIn",
        data: { id: rowData.id },
      })
        .then((res) => {
          setRefetch(() => true);
          enqueueSnackbar("Check-in geannuleerd", {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar("Check-in annuleren mislukt", {
            variant: "error",
          });
        });
    });
  };

  return (
    <>
      <MaterialTable
        isLoading={refetch}
        data={data.map((o) => ({ ...o }))} // hackey workaround to make the data dynamic (https://github.com/mbrn/material-table/issues/666)
        title="Status check-in nieuwe verhuur"
        columns={[
          {
            field: "ADRES",
            title: "Pand",
            cellStyle: {
              minWidth: 320,
            },
            render: ({ ADRES, Pand }) => (
              <ModalButton
                label={ADRES}
                id={Pand}
                reload={refetch}
                type={"property"}
              />
            ),
          },
          { field: "plaats", title: "Plaats" },
          // {
          //   field: "rent",
          //   title: "Huur (Netto)",
          //   type: "currency",
          //   currencySetting: { currencyCode: "EUR" },
          //   align: "left",
          // },
          { field: "start_rent", title: "Ingang huur", type: "date" },
          {
            field: "renter",
            title: "Huurder",
            render: (row) =>
              row.renter.length > 12
                ? row.renter.substring(0, 9) + "..."
                : row.renter,
          },
          {
            field: "pending",
            title: "Status",
            align: "center",
            customSort: (a, b) =>
              a.received_keys +
              a.initial_payment -
              (b.received_keys + b.initial_payment),
            render: (row) => (
              <>
                {row.pending ? (
                  row.received_keys && row.initial_payment ? (
                    <Tooltip title="Afronden kan" arrow>
                      <CheckCircleOutline className={classes.green} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Acties vereist" arrow>
                      <Warning className={classes.warning} />
                    </Tooltip>
                  )
                ) : row.occupied ? (
                  <Tooltip title="Afgerond" arrow>
                    <CheckCircle className={classes.green} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Leegstaand" arrow>
                    <Error className={classes.error} />
                  </Tooltip>
                )}
              </>
            ),
          },
          {
            title: "Contract genereren",
            field: "plaats",
            align: "center",
            render: (row) => (
              <>
                <VevolaTooltip
                  content={<ContractGroup row={row} />}
                  open={row.id === open}
                  placement="bottom"
                  onClose={() => setOpen(null)}
                >
                  <IconButton
                    onClick={() => setOpen(row.id)}
                    disabled={!row.pending}
                    className={classes.icon}
                  >
                    <CloudDownload />
                  </IconButton>
                </VevolaTooltip>
              </>
            ),
          },
          {
            title: "Contract getekend",
            field: "contract_signed",
            align: "center",
            render: (row) => (
              <CheckBoxColumn
                rowData={row}
                value="contract_signed"
                handleCheck={() => handleCheckClick(row, "contract_signed")}
              />
            ),
          },
          {
            title: "Borg + huur betaald",
            field: "initial_payment",
            align: "center",
            render: (row) => (
              <CheckBoxColumn
                rowData={row}
                value="initial_payment"
                handleCheck={() => handleCheckClick(row, "initial_payment")}
              />
            ),
          },
          {
            title: "Sleutels afgegeven",
            field: "received_keys",
            align: "center",
            render: (row) => (
              <CheckBoxColumn
                rowData={row}
                value={"received_keys"}
                handleCheck={() => handleCheckClick(row, "received_keys")}
              />
            ),
          },
        ]}
        actions={[
          (rowData) => ({
            disabled: !rowData.received_keys || !rowData.initial_payment,
            hidden: !rowData.pending,
            icon: tableIcons.Check,
            tooltip: "Afronden",
            onClick: (event, rowData) => handleFinishClick(rowData),
          }),
          (rowData) => ({
            hidden: !rowData.pending,
            icon: tableIcons.Clear,
            tooltip: "Check-in annuleren",
            onClick: (event, rowData) => handleCancelClick(rowData),
          }),
          {
            icon: () => <Refresh />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => setRefetch(() => true),
          },
        ]}
        options={{
          pageSize: 20,
          pageSizeOptions: [20, 50],
          emptyRowsWhenPaging: false,
          actionsColumnIndex: 0,
          maxBodyHeight: height - 128 - 80,
        }}
        icons={tableIcons}
        localization={{
          header: { actions: "" },
          toolbar: { searchPlaceholder: "Zoeken" },
          body: {
            emptyDataSourceMessage:
              "Geen recente taken voor inchecken gevonden",
          },
        }}
      />
    </>
  );
};

export default CheckInTable;
