import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useApi, { api } from "components/api";
import { useSnackbar } from "notistack";
import { produce } from "immer";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { DatePicker, TextField } from "components/form";
import { RenterContext } from "components/modal/renter";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    minWidth: 350,
  },
  formControl: {
    margin: theme.spacing(1, 0, 1, 0),
    height: 48,
  },
  selectControl: {
    margin: theme.spacing(1, 0, 1, 0),
    height: 48,
    width: "100%",
  },
  checkers: {
    margin: theme.spacing(1, 0, 2, 0),
    minWidth: 120,
    height: 48,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttons: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "space-between",
  },
  applybutton: {
    color: "white",
  },
}));

export const VevolaInput = ({
  grid = [12, 6, 6],
  r,
  handleChange,
  fields,
  className,
}) =>
  fields.map((x) => (
    <Grid
      key={x.field}
      item
      xs={x.grid ? x.grid[0] : grid[0]}
      sm={x.grid ? x.grid[1] : grid[1]}
      lg={x.grid ? x.grid[2] : grid[2]}
    >
      <TextField
        className={className}
        label={x.label ? x.label : x.field}
        value={r[x.field] ? r[x.field] : ""}
        onChange={(e) => handleChange(e, x.field)}
      />
    </Grid>
  ));

const VevolaSelect = ({
  grid = [12, 6, 6],
  r,
  handleChange,
  fields,
  className,
}) =>
  fields.map((x) => (
    <Grid key={x.field} item xs={grid[0]} sm={grid[1]} lg={grid[2]}>
      <FormControl className={className}>
        <InputLabel htmlFor={x.field}>{x.label ? x.label : x.field}</InputLabel>
        <Select
          value={r[x.field] ? r[x.field] : ""}
          // add option to also select the id
          onChange={(e) => handleChange(e, x.field)}
          inputProps={{
            name: x.label ? x.label : x.field,
            id: x.field,
          }}
        >
          {x.options.map((t) => (
            <MenuItem key={t.id || t.Id} value={t[x.value]}>
              {t[x.show ? x.show : x.value]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  ));

const EditModal = ({
  handleClose,
  reload,
  newrenter,
  passRenter = false,
  noContext = false,
  renter = null,
  options = null,
}) => {
  const classes = useStyles();

  const context = React.useContext(RenterContext);

  // This is to handle the case when we want to include the form in the checkin modal
  if (noContext === false) {
    renter = context.renter;
    options = context.options;
  }

  const { enqueueSnackbar } = useSnackbar();
  const [r, setRenter] = useState(renter);
  const handleChange = (e, n) => {
    setRenter({ ...r, [n]: e.target.value });
    if (passRenter) {
      passRenter({ ...r, [n]: e.target.value });
    }
  };

  const saveRenter = () => {
    let apicall;

    if (newrenter)
      apicall = api({ method: "post", url: "/api/renters/create", data: r });
    else apicall = api({ method: "post", url: "/api/renters/update", data: r });

    apicall
      .then((res) => {
        enqueueSnackbar("Huurder opgeslagen", {
          variant: "success",
        });
        reload();
        handleClose();
      })
      .catch((err) => {
        console.error("An api error occured");
        console.error(JSON.stringify(err));
        enqueueSnackbar("Error: Couldnt update renter", {
          variant: "error",
        });
      });
  };

  return (
    <form className={classes.form}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={6}>
          <Grid container mt={1} spacing={1}>
            <Grid item xs={12} sm={12} lg={12}>
              <h4>Gegevens Algemeen</h4>
            </Grid>
            <VevolaSelect
              grid={[4, 4, 3]}
              className={classes.selectControl}
              r={r}
              handleChange={handleChange}
              fields={[
                {
                  field: "Titel",
                  label: "Titel",
                  options: options.titles,
                  value: "Aanhef",
                  show: "Mevrouw / de heer",
                },
              ]}
            />
            <VevolaInput
              r={r}
              handleChange={handleChange}
              className={classes.formControl}
              fields={[
                { field: "Voorletters", grid: [8, 8, 6] },
                {
                  label: "Tussenvoegsel",
                  field: "tussenvoegsel",
                  grid: [4, 4, 3],
                },
                { field: "Naam", grid: [8, 8, 6] },
                { field: "Telefoon nummer1", label: "Telefoon" },
                { field: "email hoofd huurder", label: "Email" },
              ]}
            />
            <Grid item xs={12} sm={6} lg={6}>
              <DatePicker
                className={classes.formControl}
                label="Huurder sinds"
                value={r["Huurder sinds"]}
                onChange={(e) => setRenter({ ...r, "Huurder sinds": e })}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <h4>Post gegevens</h4>
            </Grid>
            <VevolaInput
              className={classes.formControl}
              r={r}
              handleChange={handleChange}
              fields={[
                { field: "particulier / bedrijf" },
                { field: "tav" },
                { field: "Adres" },
                { field: "Postcode" },
              ]}
            />

            {/* <VevolaSelect
              className={classes.selectControl}
              r={r}
              handleChange={handleChange}
              fields={[
                {
                  field: "Plaats",
                  label: "Plaats",
                  options: options.places,
                  value: "Plaats naam",
                },
              ]}
            /> */}
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl className={classes.selectControl}>
                <InputLabel htmlFor={"Plaats"}>{"Plaats"}</InputLabel>
                <Select
                  value={r["Plaats"] ? r["Plaats"] : ""}
                  // add option to also select the id
                  onChange={(e) => handleChange(e, "Plaats")}
                  inputProps={{
                    name: "Plaats",
                    id: "Plaats",
                  }}
                >
                  {options.places.map((t) => (
                    <MenuItem key={t.id || t.Id} value={t.Id}>
                      {t["Plaats naam"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={6}>
          <Grid container mt={1} spacing={1}>
            <Grid item xs={12} sm={12} lg={12}>
              <h4>Nationaliteit</h4>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <DatePicker
                className={classes.formControl}
                label="Geboortedatum"
                value={r["geboortedatum hoofd"]}
                onChange={(e) => setRenter({ ...r, "geboortedatum hoofd": e })}
              />
            </Grid>
            <VevolaSelect
              className={classes.selectControl}
              r={r}
              handleChange={handleChange}
              fields={[
                {
                  field: "geboorteland hoofd",
                  label: "Geboorteland",
                  options: options.countries,
                  value: "geboorte land",
                },
              ]}
            />
            <VevolaInput
              className={classes.formControl}
              r={r}
              handleChange={handleChange}
              fields={[{ field: "Sofie nummer" }]}
            />

            <Grid item xs={12} sm={12} lg={12}>
              <h4>Identificatie</h4>
            </Grid>
            <VevolaSelect
              className={classes.selectControl}
              r={r}
              handleChange={handleChange}
              fields={[
                {
                  field: "land",
                  label: "Land",
                  options: options.countries,
                  value: "land",
                },
                {
                  field: "soort",
                  label: "Identificatie",
                  options: options.soort,
                  value: "Identificatie",
                },
              ]}
            />
            <VevolaInput
              className={classes.formControl}
              r={r}
              handleChange={handleChange}
              fields={[{ field: "Nummer identificatie" }]}
            />
            <Grid item xs={12} sm={12} lg={12}>
              <h4>Overig</h4>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormGroup row className={classes.checkers}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={r.Loonstrookje || false}
                      onChange={(e) =>
                        setRenter({ ...r, Loonstrookje: e.target.checked })
                      }
                      value="Loonstrookje"
                      color="primary"
                    />
                  }
                  label="Loonstrookje"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={r.Verhuurdersverklaring || false}
                      onChange={(e) =>
                        setRenter({
                          ...r,
                          Verhuurdersverklaring: e.target.checked,
                        })
                      }
                      value="Verhuurdersverklaring"
                      color="primary"
                    />
                  }
                  label="Verhuurdersverklaring"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={r.btw || false}
                      onChange={(e) =>
                        setRenter({ ...r, btw: e.target.checked })
                      }
                      value="btw"
                      color="primary"
                    />
                  }
                  label="BTW"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        {passRenter ? (
          <></>
        ) : (
          <div className={classes.buttons}>
            <Button color="primary" onClick={handleClose}>
              Annuleren
            </Button>
            <Button
              className={classes.applybutton}
              color="primary"
              variant="contained"
              onClick={saveRenter}
            >
              Opslaan
            </Button>
          </div>
        )}
      </Grid>
    </form>
  );
};

export default EditModal;
