import MaterialTable from "material-table";
import React from "react";
import { formatMoney as f } from "components/format";
import { tableIcons } from "components/icons";

const DSCR = ({ packet, loading, title = "DSCR" }) => {
    
    let data = []
    const real_cost_cols = ["energy", "insurance", "municipality_taxes", "other_costs", "vve", "water"]
    if (!loading){       
        let real_explotation_cost = real_cost_cols.map(k => packet[k]).reduce((a, b) => a + b, 0)
        
        let base_nett_rent = 0.85*packet["rent"]
        let real_nett_rent = packet["rent"] - real_explotation_cost

        let base_principal = packet["current_balance"] * 0.02
        let base_interest = packet["current_balance"] * 0.045
        
        let base_bank = base_principal + base_interest
        let real_bank = packet["repayment"] + packet["interest"]
        
        let base_fc = 0.85*packet["rent"] - base_bank
        let real_fc = packet["rent"] - real_explotation_cost - real_bank

        let base_dscr = base_nett_rent / base_bank
        let real_dscr = real_nett_rent / real_bank

        data = [
            {
                name: "Bruto huuropbrengsten",
                actual: f(packet["rent"]),
                baseline: f(packet["rent"]),
                diff: null,
            },
            {
                name: "Minus exploitatiekosten",
                actual: f(real_explotation_cost),
                baseline: f(0.15*packet["rent"]),
                diff: null,
            },
            {
                name: "Netto huur",
                actual: f(real_nett_rent),
                baseline: f(base_nett_rent),
                diff: null,
                style: {
                    fontWeight: "bold" 
                }
            },
            {
                name: "Minus jaarlijkse aflossing 2%",
                actual: f(packet["repayment"]),
                baseline: f(base_principal),
                diff: f(packet["repayment"]-base_principal),
            },
            {
                name: "Minus rente 4,5%",
                actual: f(packet["interest"]),
                baseline: f(base_interest),
                diff: f(packet["interest"]-base_interest),
            },
            {
                name: "Minus erfpachtcanon",
                actual: null,
                baseline: null,
                diff: null,
            },
            {
                name: "Vrij beschikbare cashflow",
                actual: f(real_fc),
                baseline: f(base_fc),
                diff: null,
                style: {
                    fontWeight: "bold" 
                }
            },
            {
                name: "DSCR",
                actual: real_dscr.toFixed(3),
                baseline: base_dscr.toFixed(3),
                diff: null,
                style: {
                    fontWeight: "bold" 
                }
            },
        ]
    }


    return <MaterialTable
            isLoading={loading}
            columns={[
              {
                title: "Bedragen",
                field: "name",
              },
              {
                title: "Daadwerkelijke exploitatie",
                field: "actual",
              },
              {
                title: "Toetsingsnorm",
                field: "baseline",
              },
              {
                title: "Afwijking",
                field: "diff",
              }
            ]}
            data={data}
            title={title}
            options={{
              pageSize: 6,
              pageSizeOptions: false,
              paging: false,
              emptyRowsWhenPaging: false,
              search: false,
              padding: "dense",
              rowStyle: rowData => rowData.style || {}
            }}
            icons={tableIcons}
          />
}

export default DSCR