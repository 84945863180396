import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

const DataField = ({ grid = [12, 6, 4], name, value, onClick = null }) => {
  return (
    <Grid item xs={grid[0]} sm={grid[1]} lg={grid[2]}>
      {onClick ? (
        <ButtonBase
          onClick={onClick}
          style={{ display: "block", textAlign: "left" }}
        >
          <Typography mt={1} variant="caption" component="p">
            {name}
          </Typography>
          <Typography variant="body2" component="p">
            {value ? value : "-"}
          </Typography>
        </ButtonBase>
      ) : (
        <>
          <Typography mt={1} variant="caption" component="p">
            {name}
          </Typography>
          <Typography variant="body2" component="p">
            {value ? value : "-"}
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default DataField;
