import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ModalContext } from "components/modal/context";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    textTransform: "none",
    textAlign: "left",
    fontWeight: 600,
    padding: 0,

    "&> span": {
      justifyContent: "unset",
    },
  },
}));

const ModalButton = ({ label, id, reload, type }) => {
  const classes = useStyles();
  const { dispatch } = React.useContext(ModalContext);

  return (
    <Button
      disableRipple
      className={classes.button}
      onClick={() =>
        dispatch({
          type: "show",
          modalType: type,
          modalProps: {
            id: id,
            reload: reload,
            handleClose: () => dispatch({ type: "hide" }),
          },
        })
      }
    >
      {label}
    </Button>
  );
};
export { ModalButton };
