import React from "react";
import useApi from "components/api";

import { Edit } from "@material-ui/icons";

import { TabbedVevolaModal } from "components/modal/vevolaModal";
import CommentsComponent from "components/comments/comments";
import EditModal from "./edit";
import ViewModal from "./view";
import {IDefaultProps} from "./types"

const PandModal = (props : IDefaultProps) => {
  let uid = props.id !== false ? props.id : 1;
  let [{ data: pand, loading: ploading, error: pError }] = useApi(
    "/api/panden/get/" + uid
  );
  const [{ data: options, loading: oloading, error: oError }] = useApi(
    "/api/panden/options"
  );

  if (props.id === false) {
    pand = {
      pand: {},
      borg: [],
    };
    ploading = false;
    pError = false;
  }

  // Tabs to be rendered within the dialog
  const items = [
    {
      label: "Bekijken",
      component: (
        <ViewModal pand={pand} options={options} reload={props.reload} />
      ),
    },
    {
      label: "Opmerkingen",
      component: (
        <CommentsComponent selected={pand?.pand.Id} type={"PROPERTY"} />
      ),
    },
    {
      label: (
        <>
          <Edit /> <span>Bewerken</span>
        </>
      ),
      component: (
        <EditModal
          pand={pand}
          options={options}
          readonly={false}
          handleClose={props.handleClose}
          reload={props.reload}
        />
      ),
    },
  ];

  return (
    <TabbedVevolaModal
      onClose={props.handleClose}
      loading={ploading || oloading}
      error={pError || oError}
      items={items}
    />
  );
};

export default PandModal;