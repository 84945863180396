import { Refresh, tableIcons } from "components/icons";

import MaterialTable from "material-table";
import React from "react";
import { api } from "components/api";
import { formatMoney } from "components/format";

// function RefreshData() {
//   const tableRef = React.createRef();

const BankOverview = (props) => {
  const tableRef = React.createRef();
  return (
    <MaterialTable
      columns={[
        {
          field: "Datum1",
          title: "Datum",
          render: ({ parsed_date }) => parsed_date,
        },
        {
          field: "Naam / Omschrijving",
          title: "Naam",
        },
        {
          field: "Af Bij",
          title: "Af/Bij",
        },
        {
          field: "Bedrag1",
          title: "Bedrag",
          render: ({ Bedrag1 }) => formatMoney(Bedrag1),
        },
        {
          field: "Mededelingen",
          title: "Beschrijving",
        },
        {
          field: "Rekening",
          title: "Rekening",
        },
      ]}
      data={(query) =>
        api({ url: "/api/bank", params: query }).then((res) => res.data)
      }
      title={"Mutaties"}
      tableRef={tableRef}
      actions={[
        {
          icon: () => <Refresh />,
          tooltip: "Herlaad Data",
          isFreeAction: true,
          onClick: () => tableRef.current && tableRef.current.onQueryChange(),
        },
      ]}
      options={{
        pageSize: 50,
        pageSizeOptions: [10, 20, 50, 200],
        emptyRowsWhenPaging: false,
        // filtering: true
      }}
      icons={tableIcons}
      localization={{
        toolbar: { searchPlaceholder: "Zoeken" },
      }}
    />
  );
};

export default BankOverview;
