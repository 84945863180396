import PropertiesStatusTable, {
  PropertiesEmptyTable,
} from "./vacantPropertiesTable";
import React, { useState } from "react";

import BacklogRentChart from "./backlogRentChart";
import { ContextVevolaTabs } from "components/tabs";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import Grid from "@material-ui/core/Grid";
import InfoRent from "./infoRent";
import PropertiesStatusChart from "./vacantPropertiesChart";
import TotalRentChart from "./totalRentChart";
import VevolaProgress from "components/VevolaProgress";
import { makeStyles } from "@material-ui/core/styles";
import useApi from "components/api";
import { withAuthorization } from "components/session";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
  },
  paper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
  },
}));

const OverviewContainer = () => {
  const [filter, setFilter] = useState("");
  const addFilter = (filter) => setFilter(filter);
  const [value, setValue] = React.useState(0);
  const [prolonged, setProlonged] = useState([]);

  const classes = useStyles();

  const [{ data, loading, error }] = useApi(
    "/api/properties/vacantandupcoming"
  );

  const handleChange = (value) => {
    setValue(value);
  };

  if (error) return <div>Oops... an error occured!</div>;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3} className={classes.item}>
          <TotalRentChart className={classes.paper} />
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.item}>
          <BacklogRentChart className={classes.paper} addFilter={addFilter} />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.item}>
          <ContextVevolaTabs
            className={classes.paper}
            items={[
              {
                label: <EqualizerIcon />,
                component: loading ? (
                  <div>
                    <VevolaProgress />
                  </div>
                ) : (
                  <PropertiesStatusChart
                    data={data}
                    handleChange={handleChange}
                  />
                ),
              },
              {
                label: `Leegstand / Nieuwbouw (${
                  loading
                    ? "laden"
                    : data.vacant.filter((x) => x.RENTER === 268).length +
                      "/" +
                      data.vacant.filter((x) => x.RENTER === 295).length
                })`,
                component: (
                  <PropertiesEmptyTable data={data?.vacant} loading={loading} />
                ),
              },
              {
                label: `aflopend (${
                  loading ? "laden" : data.endingContract.length
                })`,
                component: (
                  <PropertiesStatusTable
                    data={data?.endingContract}
                    loading={loading}
                    sinds={"Einde contract"}
                    dagen={"Dagen tot einde"}
                    prolong={true}
                    prolonged={prolonged}
                    setProlonged={setProlonged}
                  />
                ),
              },
              {
                label: `Opkomende leegstand (${
                  loading ? "laden" : data.upcomingVacant.length
                })`,
                component: (
                  <PropertiesStatusTable
                    data={data?.upcomingVacant}
                    loading={loading}
                    sinds={"Vanaf"}
                    dagen={"Dagen tot"}
                  />
                ),
              },
            ]}
            icon={true}
            value={value}
            setValue={setValue}
          />
        </Grid>

        <Grid item xs={12}>
          <InfoRent filter={filter} addFilter={addFilter} />
        </Grid>
      </Grid>
    </>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(OverviewContainer);
