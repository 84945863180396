import React, { useState } from "react";
import { api } from "components/api";
import { DatePicker, TextField, Select, ClickSnackbar } from "components/form";
import { useSnackbar } from "notistack";
import { produce } from "immer";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    minWidth: 350,
  },
  formControl: {
    margin: theme.spacing(2, 0, 1, 0),
    minWidth: 120,
    height: 48,
  },
  head: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
  applybutton: {
    color: "white",
    marginLeft: theme.spacing(1),
  },
  checkers: {
    justifyContent: "space-between",
  },
  checkersGrid: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
}));

export const EditModal = ({ pand, options, readonly, handleClose, reload }) => {
  const classes = useStyles();
  const [p, setPand] = useState(pand.pand);
  const { enqueueSnackbar } = useSnackbar();

  options.huurders = options.huurders.map((o) => {
    const type = {
      "1": "Mevrouw",
      "2": "De heer",
      "3": "Bedrijf",
      "4": "Stichting",
      "5": "",
    };
    o.value = [type[o.Titel], o.Voorletters, o.tussenvoegsel, o.Naam].join(" ");
    return o;
  });

  const setRentPand = (prop, e) => {
    const totalRent =
      Number(e.target.value) +
      Number(p["Bruto huur"]) +
      Number(p["Service kosten"]) +
      Number(p.Stoffering) -
      Number(p[prop]);
    const newState = produce(p, (draftstate) => {
      draftstate[prop] = e.target.value;
      draftstate["Maand huur"] = totalRent;
    });
    setPand(newState);
  };

  const savePand = () => {
    let apicall;
    if (readonly) {
      const data = {
        id: p.id,
        opmerking: p.opmerking,
        "Einde verhuur per": p["Einde verhuur per"],
        "begin controle pand": p["begin controle pand"],
        "eind controle pand": p["eind controle pand"],
        "Reden opzegging": p["Reden opzegging"],
      };

      apicall = api({ method: "post", url: "/api/panden/update", data: data });
    } else {
      const data = {
        id: p.id,
        pand_sub: p["pand-sub"],
        verhuur: {
          "Maand huur": p["Maand huur"],
          // "Einde verhuur per": p["Einde verhuur per"],
          // "Reden opzegging": p["Reden opzegging"],
          "begin controle pand": p["begin controle pand"],
          "eind controle pand": p["eind controle pand"],
          "Bruto huur": p["Bruto huur"],
          "Service kosten": p["Service kosten"],
          Stoffering: p.Stoffering,
          Gemeubileerd: p.Gemeubileerd,
          Gestoffeerd: p.Gestoffeerd,
          "Stookkosten en gas": p["Stookkosten en gas"],
          "Elektra en water": p["Elektra en water"],
          Internet: p.Internet,
          // "maand begin huur": p["maand begin huur"],
        },
        sub_tabel_onroerend_goed: {
          soort: p.soort[0],
          "sub nummer": p["sub nummer"],
          opmerking: p.opmerking,
          "Oppervlakte (m2)": p["Oppervlakte (m2)"],
        },
        onroerend_goed: {
          Straatnaam: p.Straatnaam,
          nr: p.nr,
          toevoegsel: p.toevoegsel,
          Postcode: p.Postcode,
          plaats: p.plaats,
        },
      };
      apicall = api({ method: "post", url: "/api/panden/update", data: data });
    }

    apicall
      .then((res) => {
        enqueueSnackbar("Pand bijgewerkt", {
          variant: "success",
        });
        handleClose();
        reload();
      })
      .catch((err) => {
        console.error("An api error occured");
        console.error(JSON.stringify(err));
        enqueueSnackbar("Kan pand niet bijwerken", {
          variant: "error",
        });
      });
  };

  return (
    <form className={classes.form}>
      {" "}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} lg={12}>
          <h3 className={classes.head}>Gegevens Pand</h3>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <TextField
            className={classes.head}
            label="Straanaam"
            readonly={readonly}
            value={p.Straatnaam}
            onChange={(e) => setPand({ ...p, Straatnaam: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={1}>
          <TextField
            className={classes.head}
            label="Nummer"
            type="number"
            readonly={readonly}
            value={p.nr}
            onChange={(e) => setPand({ ...p, nr: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <TextField
            className={classes.head}
            label="Toevoegsel"
            readonly={readonly}
            value={p.Toevoegsel}
            onChange={(e) => setPand({ ...p, Toevoegsel: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={1}>
          <TextField
            className={classes.head}
            label="Sub"
            readonly={readonly}
            value={p["sub nummer"]}
            onChange={(e) => setPand({ ...p, "sub nummer": e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <TextField
            className={classes.head}
            label="Plaats"
            readonly={readonly}
            value={p.plaats}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <TextField
            label="Opmerkingen"
            multiline={true}
            onChange={(e) => setPand({ ...p, opmerking: e.target.value })}
            value={p["opmerking"]}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12}>
          <h3 className={classes.head}>Huurgegevens</h3>
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <ClickSnackbar>
            <Select
              className={classes.head}
              data={p}
              field="Verhuurd aan"
              options={options.huurders}
              readonly={true}
            />
          </ClickSnackbar>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ClickSnackbar>
            <DatePicker
              className={classes.head}
              label="Ingang huur"
              value={p["Ingang huur"]}
              readonly={true}
              onChange={(e) => setPand({ ...p, "Huurder sinds": e })}
            />
          </ClickSnackbar>
        </Grid>
        <Grid item xs={12} sm={12} lg={7} className={classes.checkersGrid}>
          <FormGroup row className={classes.checkers}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={p["begin controle pand"]}
                  onChange={(e) =>
                    setPand({
                      ...p,
                      "begin controle pand": e.target.checked,
                    })
                  }
                  value="begin controle pand"
                  color="primary"
                />
              }
              label="Begin controle pand"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={p["eind controle pand"]}
                  onChange={(e) =>
                    setPand({
                      ...p,
                      "eind controle pand": e.target.checked,
                    })
                  }
                  value="eind controle pand"
                  color="primary"
                />
              }
              label="Eind controle pand"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <h3 className={classes.head}>Financieel</h3>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            className={classes.head}
            label="Maand huur (bruto)"
            type="number"
            onChange={(e) => setRentPand("Bruto huur", e)}
            value={p["Bruto huur"]}
            readonly={readonly}
            startAdornment={"€"}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            className={classes.head}
            label="Service kosten"
            type="number"
            onChange={(e) => setRentPand("Service kosten", e)}
            value={p["Service kosten"]}
            readonly={readonly}
            startAdornment={"€"}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            className={classes.head}
            label="Stoffering kosten"
            type="number"
            onChange={(e) => setRentPand("Stoffering", e)}
            value={p["Stoffering"]}
            readonly={readonly}
            startAdornment={"€"}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            className={classes.head}
            label="Maand huur (netto)"
            type="number"
            onChange={(e) => setPand({ ...p, "Maand huur": e.target.value })}
            value={p["Maand huur"]}
            readonly={true}
            startAdornment={"€"}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <h3 className={classes.head}>Faciliteiten</h3>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <FormGroup row className={classes.checkers}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={p["Stookkosten en gas"]}
                  onChange={(e) =>
                    setPand({
                      ...p,
                      "Stookkosten en gas": e.target.checked,
                    })
                  }
                  value="Stookkosten en gas"
                  color="primary"
                  disabled={readonly}
                />
              }
              label="Stookkosten en gas"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={p["Elektra en water"]}
                  onChange={(e) =>
                    setPand({
                      ...p,
                      "Elektra en water": e.target.checked,
                    })
                  }
                  value="Elektra en water"
                  color="primary"
                  disabled={readonly}
                />
              }
              label="Elektra en water"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={p["Internet"]}
                  onChange={(e) =>
                    setPand({ ...p, Internet: e.target.checked })
                  }
                  value="Internet"
                  color="primary"
                  disabled={readonly}
                />
              }
              label="Internet"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={p["Gemeubileerd"]}
                  onChange={(e) =>
                    setPand({ ...p, Gemeubileerd: e.target.checked })
                  }
                  value="Gemeubileerd"
                  color="primary"
                  disabled={readonly}
                />
              }
              label="Gemeubileerd"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={p["Gestoffeerd"]}
                  onChange={(e) =>
                    setPand({ ...p, Gestoffeerd: e.target.checked })
                  }
                  value="Gestoffeerd"
                  color="primary"
                  disabled={readonly}
                />
              }
              label="Gestoffeerd"
            />
          </FormGroup>
        </Grid>
        <div className={classes.buttons}>
          <Button color="primary" onClick={handleClose}>
            Annuleren
          </Button>
          <Button
            className={classes.applybutton}
            color="primary"
            variant="contained"
            onClick={savePand}
          >
            Opslaan
          </Button>
        </div>
      </Grid>
    </form>
  );
};

export default EditModal;
