import React from "react";
import MaterialTable from "material-table";
import useApi from "components/api";
import { ModalButton } from "components/form";
import { tableIcons } from "./../huurders/infoHuurders";

import Refresh from "@material-ui/icons/Refresh";
import useWindowResize from "components/hooks/useWindowResize";

const get_month_name = (idx) => {
  let objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(objDate.getMonth() + idx);

  let month = objDate.toLocaleString("nl-nl", { month: "long" });
  return month.charAt(0).toUpperCase() + month.slice(1);
};

const MonthPaid = (amount) => {
  if (amount === 0) return "";
  else return "€ " + amount;
};

const PandenContainer = () => {
  const [{ data, loading, error }, refetch] = useApi("/api/panden/info");
  const { height } = useWindowResize();

  if (error) {
    console.error(error);
    return "an error occured";
  }

  return (
    <>
      <MaterialTable
        isLoading={loading}
        columns={[
          {
            field: "street",
            title: "Straat",
            cellStyle: {
              minWidth: 300,
            },
            // defaultSort: "asc",
            render: ({ street, id }) => (
              <ModalButton
                label={street}
                id={id}
                reload={() => refetch()}
                type={"property"}
              />
            ),
          },
          {
            field: "nr",
            title: "Nr",
            render: ({ nr, id }) => (
              <ModalButton
                label={nr}
                id={id}
                reload={() => refetch()}
                type={"property"}
              />
            ),
          },
          { field: "postcode", title: "Postcode" },
          { field: "plaats", title: "Plaats" },
          {
            field: "huur per maand",
            title: "Huur",
            render: ({ rent }) => (rent ? "€ " + rent : ""),
          },
          {
            field: "renter_name",
            title: "Huurder",
            cellStyle: {
              minWidth: 300,
            },
            render: ({ renter_name, renter_id }) => (
              <ModalButton
                label={renter_name}
                id={renter_id}
                reload={refetch}
                type={"renter"}
              />
            ),
          },
          {
            field: "open_2_month",
            title: get_month_name(-2),
            cellStyle: (amount) => ({
              backgroundColor: amount === 0 ? "#92D050" : "inherit",
              borderRight: "1px solid white",
            }),
            render: ({ open_2_month }) => MonthPaid(open_2_month),
          },
          {
            field: "open_1_month",
            title: get_month_name(-1),
            cellStyle: (amount) => ({
              backgroundColor: amount === 0 ? "#92D050" : "inherit",
            }),
            render: ({ open_1_month }) => MonthPaid(open_1_month),
          },
          {
            field: "open_0_month",
            title: get_month_name(0),
            cellStyle: (amount) => ({
              backgroundColor: amount === 0 ? "#92D050" : "inherit",
              borderLeft: "1px solid white",
            }),
            render: ({ open_0_month }) => MonthPaid(open_0_month),
          },
        ]}
        data={data}
        title="Panden overzicht"
        actions={[
          {
            icon: () => <Refresh />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => refetch(),
          },
        ]}
        options={{
          pageSize: 50,
          pageSizeOptions: [10, 20, 50, 200],
          emptyRowsWhenPaging: false,
          maxBodyHeight: height - 128 - 24,
        }}
        icons={tableIcons}
        localization={{
          toolbar: { searchPlaceholder: "Zoeken" },
        }}
      />
      {/* <div className={classes.buttons}>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.addverhuur}
          onClick={() => setEdit(false)}
        >
          <AddIcon />
        </Fab>
      </div> */}
    </>
  );
};

export default PandenContainer;
