import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  readonly: {
    "& ::before": {
      display: "none",
    },
    "& ::after": {
      display: "none",
    },
    "& svg": { display: "none" },
  },
}));

const VevolaSelect = ({
  data,
  field,
  label,
  options,
  onChange = null,
  className = null,
  readonly = false,
  disabled = false,
  onClick = null,
}) => {
  const classes = useStyles();
  return (
    <FormControl
      className={[
        className,
        classes.style,
        readonly ? classes.readonly : "",
      ].join(" ")}
      margin="normal"
      disabled={disabled}
    >
      <InputLabel htmlFor={field}>{label ? label : field}</InputLabel>
      <Select
        value={data[field]}
        onChange={(e) =>
          onChange != null
            ? onChange({ ...data, [field]: e.target.value })
            : null
        }
        onClick={onClick != null ? onClick : null}
        inputProps={{
          name: label ? label : field,
          id: field,
          readOnly: readonly,
        }}
      >
        {options.map((t) => (
          <MenuItem key={t.Id || t.id} value={t.id || t.Id}>
            {t.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { VevolaSelect as Select };
