import Chart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { formatMoney } from "components/format";
import useApi from "components/api";
// import BeatLoader from "react-spinners"

const BacklogChart = ({ data, addFilter }) => {
  const categories = data.map((d) => d.MONTH);
  const series = data.map((d) => d.SUM);

  const total = Math.round(series.reduce((a, b) => a + b, 0) * 100) / 100;

  const options = {
    chart: {
      id: "rent-bar",
      stacked: true,
      toolbar: {
        show: false,
        offsetY: 24,
        offsetX: -15,
        tools: {
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true,
        },
        autoSelected: "pan",
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          addFilter(data[config.dataPointIndex].MONTH);
        },
      },
    },
    title: {
      text: "Openstaande huur: " + formatMoney(total),
      offsetY: 10,
      offsetX: 14,
      margin: 50,
      style: {
        fontSize: "1.25rem",
        fontWeight: 500,
        color: "black",
      },
    },
    colors: ["#EC884D", "#4CC7EC", "#fbde18", "#ef7963"],
    yaxis: {
      title: {
        text: "Totaal (€)",
      },
    },
    xaxis: {
      categories: categories,
    },
    tooltip: {
      y: {
        formatter: (val) => formatMoney(val),
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          orientation: "vertical",
        },
      },
    },
  };

  return (
    <Chart
      options={options}
      series={[{ name: "Bedrag", data: series }]}
      type="bar"
      height="420"
    />
  );
};

const BacklogRentChart = ({ className, addFilter }) => {
  const [{ data, loading, error }] = useApi("/api/open_rent_month");
  if (error) {
    console.error(error);
    return "an error occured";
  }

  if (loading) {
    return <Paper className={className}></Paper>;
  }
  return (
    <Paper className={className}>
      <BacklogChart data={data} addFilter={addFilter} />
    </Paper>
  );
};

export default BacklogRentChart;
