import React from "react";
import { TabbedVevolaModal } from "components/modal/vevolaModal";
import useApi from "components/api";
import ViewModal from "./view";
import EditModal from "./edit";
import { IDefaultProps, IRenterContextProps } from "./types";

import { Edit } from "@material-ui/icons";

import CommentsComponent from "components/comments/comments";

export const RenterContext = React.createContext<IRenterContextProps | null>(
  null
);

const RenterModal = (props: IDefaultProps) => {
  let uid = props.id !== false ? props.id : 1;

  const [{ data, loading, error: apiError }] = useApi(
    "/api/renters/get/" + uid
  );

  // Define the objects that wil be passed down to the tabs
  const renter =
    props.id !== false
      ? data?.renter
      : {
          Loonstrookje: false,
          Verhuurdersverklaring: false,
          btw: false,
        };
  const panden = props.id !== false ? data?.panden : [];

  // Tabs to be rendered within the dialog
  const items = [
    {
      label: "Bekijken",
      component: <ViewModal />,
    },
    {
      label: "Opmerkingen",
      component: <CommentsComponent selected={data?.Id} type={"RENTER"} />,
    },
    {
      label: (
        <>
          <Edit /> <span>{props.id === false ? "Toevoegen" : "Bewerken"}</span>
        </>
      ),
      component: (
        <EditModal
          handleClose={props.handleClose}
          reload={props.reload}
          newrenter={props.id === false}
        />
      ),
    },
  ];

  const renterContext: IRenterContextProps = {
    renter: renter,
    panden: panden,
    options: data?.options,
    openRent: data?.openRent,
  };

  return (
    <RenterContext.Provider value={renterContext}>
      <TabbedVevolaModal
        onClose={props.handleClose}
        onlyEdit={props.id === false}
        loading={loading}
        error={apiError}
        items={items}
      />
    </RenterContext.Provider>
  );
};

export default RenterModal;
