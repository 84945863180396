import app, { analytics } from "firebase";
import "firebase/auth";
import "firebase/storage";
import "firebase/firebase-firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBA2TnAC2ncXocDrN4R-84nGIVHiYIirWY",
  authDomain: "vevola-fbf8e.firebaseapp.com",
  databaseURL: "https://vevola-fbf8e.firebaseio.com",
  projectId: "vevola-fbf8e",
  storageBucket: "vevola-fbf8e.appspot.com",
  messagingSenderId: "1075029957576",
  appId: "1:1075029957576:web:8c3e7f8db5287c67",
  measurementId: "G-WH2L6L9R8R",
};

app.initializeApp(firebaseConfig);
analytics();
const Firebase = {
  auth: app.auth,
  firestore: app.firestore,

  doCreateUserWithEmailAndPassword: (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password),

  doSignInWithEmailAndPassword: (email, password) =>
    app.auth().signInWithEmailAndPassword(email, password),

  doSignOut: () => app.auth().signOut(),

  doPasswordReset: (email) => app.auth().sendPasswordResetEmail(email),

  doPasswordUpdate: (password) =>
    app.auth().currentUser.updatePassword(password),

  store: (generateDoc) => {
    app
      .storage()
      .ref()
      .child("tag-example.docx")
      .getDownloadURL()
      .then((url) => {
        generateDoc(url);
      });
  },

  doClaimCheck: (claims, callback) => {
    app
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        const claimsAcces = {};
        let admin = false;
        claims.forEach((claim) => {
          const email = idTokenResult.claims.email;
          if (
            email === "tim@ellemeet.nl" ||
            email === "olafwallaart@gmail.com"
            // email === "hans@mekes.nu"
          ) {
            claimsAcces[claim.claim] = true;
            admin = true;
          } else if (idTokenResult.claims[claim.claim] !== undefined)
            claimsAcces[claim.claim] = idTokenResult.claims[claim.claim];
          else claimsAcces[claim.claim] = claim.access;
        });

        analytics().logEvent("claim_check", {
          admin,
          name: idTokenResult.claims.name,
          email: idTokenResult.claims.email,
        });

        analytics().logEvent(idTokenResult.claims.name);
        analytics().setUserProperties({
          admin,
          name: idTokenResult.claims.name,
        });
        callback(claimsAcces);
        // Confirm the user is an Admin.
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

export default Firebase;
