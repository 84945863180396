import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Grid } from "@material-ui/core";

import firebase from "firebase";

import CommentsList from "./commentsList";
import CommentForm from "./commentForm";

const CommentsComponent = ({ selected, type }) => {
  const env = process.env.NODE_ENV === "development" ? "-dev" : "";

  const [values, loading] = useCollectionData(
    firebase
      .firestore()
      .doc("comments-" + type + "/" + selected)
      .collection("comments" + env)
      .orderBy("createdAt", "desc"),
    {
      idField: "id",
    }
  );

  const onSubmit = (text) => {
    firebase
      .firestore()
      .doc("comments-" + type + "/" + selected)
      .collection("comments" + env)
      .doc()
      .set({
        avatarUrl: "test",
        authorUrl: firebase.auth().currentUser.email,
        fullName: firebase.auth().currentUser.displayName,
        createdAt: new Date(),
        text: text,
      });
  };

  const onDelete = (comment) => {
    firebase
      .firestore()
      .doc("comments-" + type + "/" + selected)
      .collection("comments" + env)
      .doc(comment)
      .delete();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} lg={12}>
        <h3>Opmerkingen</h3>
        <CommentsList comments={values} onDelete={onDelete} />
        <CommentForm onSubmit={onSubmit} />
      </Grid>
    </Grid>
  );
};

export default CommentsComponent;
