import React from "react";

import List from "@material-ui/core/List";

import Comment from "./comment";

const CommentsList = ({ comments, onDelete }) => {
  if (comments.length === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <i>Nog geen comments</i>
      </div>
    );
  }
  return (
    <List>
      {comments.map((comment, index) => (
        <Comment
          key={comment.fullName + comment.createdAt.toString() + index}
          comment={comment}
          onDelete={() => onDelete(comment.id)}
        />
      ))}
    </List>
  );
};

export default CommentsList;
