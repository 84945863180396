import React, { ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  readonly: {
    "& ::before": {
      display: "none",
    },
    "& ::after": {
      display: "none",
    },
  },
}));

export const ClickSnackbar = (props: {
  children: React.ReactNode;
  readOnly: boolean;
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    enqueueSnackbar("Sorry, dit veld kan niet worden aangepast", {
      variant: "warning",
      preventDuplicate: true,
    });
  };

  if (props.readOnly === false) {
    return <>{props.children}</>;
  }

  return <div onClick={handleOpen}>{props.children}</div>;
};

type VevolaMoneyFieldProps = {
  rent: any;
  handleRent: (e: any, value: string, totalRent: number) => void;
};
export const VevolaMoneyField = ({
  rent,
  handleRent,
}: VevolaMoneyFieldProps) => {
  const setRentPand = (e: string, value: string) => {
    const totalRent =
      Number(e) +
      Number(rent["Bruto huur"]) +
      Number(rent["Service kosten"]) +
      Number(rent.Stoffering) -
      Number(rent[value]);
    handleRent(e, value, totalRent);
    // handleRent(totalRent, "Maand huur");
  };

  return (
    <>
      <TextField
        label="Maand huur (bruto)"
        type="number"
        margin="dense"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
          placeholder: "0",
        }}
        onChange={(e) => {
          setRentPand(e.target.value, "Bruto huur");
        }}
        value={rent["Bruto huur"]}
      />
      <TextField
        label="Stoffering kosten"
        type="number"
        margin="dense"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
        }}
        onChange={(e) => {
          setRentPand(e.target.value, "Stoffering");
        }}
        value={rent.Stoffering}
      />
      <TextField
        label="Service kosten"
        type="number"
        margin="dense"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
        }}
        onChange={(e) => {
          setRentPand(e.target.value, "Service kosten");
        }}
        value={rent["Service kosten"]}
      />
      <TextField
        label="Maand huur (netto)"
        type="number"
        margin="dense"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
          readOnly: true,
        }}
        onChange={(e) => setRentPand(e.target.value, "Maand huur")}
        value={rent["Maand huur"] || 0}
      />
    </>
  );
};

type VevolaTextFieldProps = {
  label: string;
  value: string;
  className: any;
  readonly: boolean;
  disabled: boolean;
  onChange:
    | undefined
    | ((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void);
  type: string;
  multiline: boolean;
  startAdornment: null | string;
};
const VevolaTextField = ({
  label,
  value,
  className = null,
  readonly = false,
  disabled = false,
  onChange = undefined,
  type = "text",
  multiline = false,
  startAdornment = null,
  ...props
}: VevolaTextFieldProps) => {
  const classes = useStyles();

  return (
    <ClickSnackbar readOnly={readonly}>
      <TextField
        id={label.replace(/\s+/g, "-").toLowerCase()}
        label={label}
        disabled={disabled}
        value={value ? value : ""}
        className={[className, readonly ? classes.readonly : ""].join(" ")}
        margin="normal"
        type={type}
        fullWidth={true}
        InputProps={{
          readOnly: readonly,
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
        }}
        onChange={onChange}
        multiline={multiline}
        {...props}
      />
    </ClickSnackbar>
  );
};

export { VevolaTextField as TextField };
