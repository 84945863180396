import React, { Fragment } from "react";
import { apiWithTimeout } from "components/api";
import { useSnackbar } from "notistack";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import fileSaver from "file-saver";

const contracts = [
  {
    id: 0,
    name: "Contract",
    endpoint: "/api/contract/get/",
    schiedamOnly: false,
    rotterdamOnly: false,
  },
  {
    id: 1,
    name: "Algemene Bepalingen",
    endpoint: "/api/algemenebepalingen/get/",
    schiedamOnly: false,
    rotterdamOnly: false,
  },
  {
    id: 2,
    name: "Verhuurdersverklaring",
    endpoint: "/api/verhuurdersverklaring/get/",
    schiedamOnly: false,
    rotterdamOnly: true,
  },
  {
    id: 3,
    name: "huisvestingsvergunning",
    endpoint: "/api/huisvestingsvergunning/get/",
    schiedamOnly: false,
    rotterdamOnly: true,
  },
  {
    id: 4,
    name: "Bereidverklaring",
    endpoint: "/api/bereidverklaring/get/",
    schiedamOnly: true,
    rotterdamOnly: false,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function ContractGroup({ row }) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleContractClick = (id, endpoint) => {
    const action = (key) => (
      <Fragment>
        <CircularProgress />
      </Fragment>
    );

    enqueueSnackbar("Contract wordt gegenereerd", {
      action,
      preventDuplicate: true,
    });

    apiWithTimeout({
      method: "get",
      url: endpoint + id,
      responseType: "arraybuffer",
      ms: 5000,
    })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: res.headers["Content-Type"],
        });
        fileSaver.saveAs(blob, res.headers.name);
        closeSnackbar();
      })
      .catch((error) => {
        closeSnackbar();
        enqueueSnackbar("Contract genereren mislukt", {
          variant: "error",
        });
      });
  };

  return (
    <div className={classes.root}>
      <ButtonGroup
        orientation="vertical"
        variant="text"
        color="primary"
        aria-label="text primary button group"
      >
        {contracts.map((contract) => {
          if (
            contract.schiedamOnly === contract.rotterdamOnly ||
            (contract.rotterdamOnly === true && row.rotterdamCheck === true) ||
            (contract.schiedamOnly === true && row.schiedamCheck === true)
          ) {
            return (
              <Button
                key={contract.id}
                onClick={() => handleContractClick(row.id, contract.endpoint)}
              >
                {contract.name}
              </Button>
            );
          } else return null;
        })}
      </ButtonGroup>
    </div>
  );
}
