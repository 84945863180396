import React, { useState } from "react";
import produce from "immer";

import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";

import { withFirebase } from "components/firebase";

export const PasswordForgetPage = () => (
  <div>
    <h1>Wachtwoord Vergeten</h1>
    <PasswordForgetForm />
  </div>
);

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2, 0)
  }
}));

const INITIAL_STATE = {
  email: "",
  error: null
};

const PasswordForgetForm = props => {
  const classes = useStyles();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmit = event => {
    const { email } = state;
    props.firebase
      .doPasswordReset(email)
      .then(() => {
        setState(produce(state, () => INITIAL_STATE));
      })
      .catch(error => {
        setState(
          produce(state, draftstate => {
            draftstate.error = error;
          })
        );
      });
    event.preventDefault();
  };
  const onChange = event => {
    setState(
      produce(state, draftstate => {
        draftstate[event.target.name] = event.target.value;
      })
    );
  };

  const { email, error } = state;
  const isInvalid = email === "";
  return (
    <form onSubmit={onSubmit}>
      <TextField
        name="email"
        value={state.email}
        onChange={onChange}
        type="text"
        label="Email Adres"
        fullWidth
      />
      <Button
        className={classes.button}
        variant="contained"
        disabled={isInvalid}
        type="submit"
      >
        Stuur mij een mail
      </Button>
      {error && <p>{error.message}</p>}
    </form>
  );
};

export default withFirebase(PasswordForgetForm);
