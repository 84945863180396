import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: [theme.spacing(2), 0].join("px "),
      width: "100%",
    },
  },
  button: {
    margin: [theme.spacing(2), 0].join("px "),
  },
}));

const CommentForm = (props) => {
  const classes = useStyles();

  const [text, setText] = useState("");
  const [enterPressed, setEnterPressed] = useState(false);

  const onChange = (e) => {
    const { value } = e.target;
    if (enterPressed) {
      setEnterPressed(false);
    } else {
      setText(value);
    }
  };

  const onEnter = (e) => {
    e.stopPropagation();
    if (e.key === "Enter" && !e.shiftKey) {
      setEnterPressed(true, () => {
        onSubmit();
      });
    }
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const tempText = text.trim().replace(/\n{3,}/g, "\n\n");
    props.onSubmit(tempText);
    setText("");
  };

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <TextField
        name="comment-text"
        id="comment-text"
        label="Typ een comment"
        multiline
        rowsMax={4}
        value={text}
        onChange={onChange}
        onKeyPress={onEnter}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Comment
      </Button>
    </form>
  );
};

export default CommentForm;
