import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { api } from "components/api";
import Moment from "moment";
import "moment/locale/nl";

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "left",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "left",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "left",
    color: "grey",
  },
  table: {
    display: "table",
    width: "100%",
    border: "none",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "20%",
    border: "none",
  },
  tableColBig: {
    width: "40%",
    border: "none",
  },
  tableCell: {
    textAlign: "left",
    marginTop: 5,
    fontSize: 10,
    fontWeight: "bold",
  },
  tableCellBold: {
    textAlign: "left",
    marginTop: 15,
    fontSize: 10,
  },
  tableCellHeader: {
    textAlign: "left",
    marginTop: 5,
    fontSize: 12,
  },
});

export async function getProps() {
  const response = await api({
    url: "/api/renters/export",
  });
  const resData = await response.data;
  return resData;
}

// Create Document Component
export const PdfHuurders = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text>
          VEVOLA HUURDEROVERZICHT PER {Moment().format("LL").toUpperCase()}
        </Text>
      </View>
      <View style={styles.table}>
        {/* TableHeader */}
        <View style={styles.tableRow}>
          <View style={styles.tableColBig}>
            <Text style={styles.tableCellHeader}>Naam</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellHeader}>Start contract per</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellHeader}>Looptijd (dagen)</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellHeader}>Totaal bedrag huur</Text>
          </View>
        </View>
      </View>
      {data
        .sort((a, b) => {
          return b["Maand huur"] - a["Maand huur"];
        })
        .map((row, index) => (
          <React.Fragment key={index}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColBig}>
                  <Text style={styles.tableCellBold}>
                    {[row.Titel, row.Voorletters, row.tussenvoegsel, row.Naam]
                      .filter(Boolean)
                      .join(" ")}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellBold}>
                    {Moment(row["Ingang huur"]).format("LL")}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellBold}>{row["Looptijd"]}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellBold}>
                    {row["Maand huur"]} EUR
                  </Text>
                </View>
              </View>
            </View>
          </React.Fragment>
        ))}
    </Page>
  </Document>
);
