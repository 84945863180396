import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";

import useApi from "components/api";
import { ModalButton } from "components/form";
import useWindowResize from "components/hooks/useWindowResize";

import { tableIcons } from "./../huurders/infoHuurders";

import Refresh from "@material-ui/icons/Refresh";

const month = [];
month[0] = "Januari";
month[1] = "Februari";
month[2] = "Maart";
month[3] = "April";
month[4] = "Mei";
month[5] = "Juni";
month[6] = "Juli";
month[7] = "Augustus";
month[8] = "September";
month[9] = "Oktober";
month[10] = "November";
month[11] = "December";

const InfoRent = ({ filter, addFilter }) => {
  const [{ data, loading, error }, refetch] = useApi("/api/openrent");
  const { height } = useWindowResize();

  const [dataFiltered, setDataFilterd] = useState(data?.open_rent);

  useEffect(() => {
    if (data) {
      const dataFiltered = data.open_rent.filter((x) => {
        const d = new Date(x["MAAND"]);
        return filter
          ? month[d.getMonth()].toUpperCase() === filter.toUpperCase()
          : true;
      });
      setDataFilterd(dataFiltered);
    }
  }, [filter, data]);

  const reload = () => {
    addFilter("");
    refetch();
  };

  if (error) {
    console.error(error);
    return "an error occured";
  }

  let title = "Openstaande huur";

  if (!loading) {
    if (filter !== "") {
      title += " ALLEEN " + filter.toUpperCase();
    } else {
      const per = new Date(data.last_update);

      title += ` per ${per.getDate()} ${
        month[per.getMonth()]
      } ${per.getFullYear()}`;
      title += ` (${data.open_rent.length})`;
    }
  }
  return (
    <>
      <MaterialTable
        isLoading={loading}
        columns={[
          {
            field: "Expr1",
            title: "Adres",
            cellStyle: {
              minWidth: 350,
            },
            render: ({ Expr1, property_id }) => (
              <ModalButton
                label={Expr1}
                id={property_id}
                reload={refetch}
                type={"property"}
              />
            ),
          },
          {
            field: "Expr6",
            title: "Openstaand",
            render: (row) => `€ ${row["Expr6"]}`,
          },
          // {
          //     field: "Maand huur", title: "Openstaand",
          //     render: row => `€ ${row["Maand huur"]}`
          // },
          {
            field: "MAAND",
            title: "Maand",
            render: (row) => {
              if (row["MAAND"]) {
                const d = new Date(row["MAAND"]);

                return `${month[d.getMonth()]} ${d.getFullYear()}`;
              } else return "";
            },
            customFilterAndSearch: (term, rowData) => {
              const d = new Date(rowData["MAAND"]);

              return (
                `${month[d.getMonth()]} ${d.getFullYear()}`
                  .toUpperCase()
                  .indexOf(term.toUpperCase()) !== -1
              );
            },
          },
          {
            field: "Naam",
            title: "Huurder",
            cellStyle: {
              minWidth: 350,
            },
            render: (row) => (
              <ModalButton
                label={row.Naam}
                id={row["huurders id"]}
                reload={refetch}
                type={"renter"}
              />
            ),
          },

          { field: "Telefoon nummer1", title: "Telefoon" },
          {
            field: "email hoofd huurder",
            title: "Email",
            render: (row) => (
              <a href={"mailto:" + row["email hoofd huurder"]}>
                {row["email hoofd huurder"]}
              </a>
            ),
          },
          {
            field: "Gem Dag",
            title: "Verwacht",
            render: (row) => {
              if (row["MAAND"] && row["Gem Dag"]) {
                const d = new Date(row["MAAND"]);
                d.setDate(row["Gem Dag"]);

                const text = `${d.getDate()} ${month[d.getMonth()]}`;
                // tyle={{ color: "#B0B700", fontWeight: "bold" }}
                if (new Date() > d) {
                  return (
                    <b style={{ color: "red", fontWeight: "bold" }}>{text}</b>
                  );
                } else return text;
              } else return "";
            },
          },
        ]}
        data={dataFiltered}
        title={title}
        actions={[
          {
            icon: () => <Refresh />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => reload(),
          },
        ]}
        options={{
          pageSize: 50,
          pageSizeOptions: [10, 20, 50, 200],
          emptyRowsWhenPaging: false,
          maxBodyHeight: height - 128 - 24,
        }}
        icons={tableIcons}
        localization={{
          toolbar: { searchPlaceholder: "Zoeken" },
        }}
      />
    </>
  );
};

export default InfoRent;
