import {
  Person as AccountIcon,
  Settings as AdminIcon,
  HowToReg as CheckInIcon,
  Dashboard as DashboardIcon,
  Home as HomeIcon,
  SupervisorAccount as HuurderIcon,
  AccountBalance as NBIIcon,
  ShowChart
} from "@material-ui/icons";
import React, { useContext } from "react";

import Account from "dashboard/pages/account";
import Admin from "dashboard/pages/admin";
import AppBar from "@material-ui/core/AppBar";
import BankOverview from "./pages/bank/index"
import CheckIn from "./pages/checkin";
// Pages
import Huurders from "dashboard/pages/huurders";
import Nbi from "./pages/nbi";
import Overview from "dashboard/pages/overview/overviewContainer";
import Panden from "dashboard/pages/panden/pandenContainer";
import { StateContext } from "./index";
import Toolbar from "@material-ui/core/Toolbar";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import vevolaColors from "vevolaColors";

const useStyles = makeStyles((theme) => ({
  layout: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
    backgroundColor: vevolaColors.grey3,
  },
  content: {
    width: `calc(100vw - 240px)`,
    position: "relative",
    flexGrow: 1,
  },
  head: {
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  main: {
    padding: theme.spacing(3),
    minHeight: "100vh",
  },
  mainShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  appcolor: {
    background: "white",
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: "space-between",
  },
}));

export const structure = [
  {
    id: 0,
    component: Overview,
    label: "Dashboard",
    claim: "overview",
    access: true,
    link: "/overview",
    icon: <DashboardIcon />,
    position: "top",
  },
  {
    id: 2,
    component: Panden,
    label: "Panden",
    claim: "panden",
    access: false,
    link: "/panden",
    icon: <HomeIcon />,
    position: "top",
  },
  {
    id: 1,
    component: Huurders,
    label: "Huurders",
    claim: "renters",
    access: false,
    link: "/huurders",
    icon: <HuurderIcon />,
    position: "top",
  },
  {
    id: 3,
    component: Nbi,
    label: "Netto besteedbaar inkomen",
    claim: "nbi",
    access: false,
    link: "/nbi",
    icon: <ShowChart />,
    position: "top",
  }, 
  {
    id: 7,
    component: BankOverview,
    label: "Af- en Bijschrijvingen",
    claim: "nbi",
    access: false,
    link: "/bank",
    icon: <NBIIcon />,
    position: "top",
  },
  {
    id: 6,
    component: CheckIn,
    label: "Check-in & status",
    claim: "renters",
    access: false,
    link: "/check-in",
    icon: <CheckInIcon />,
    position: "top",
  },
  {
    id: 4,
    component: Admin,
    label: "Admin",
    claim: "admin",
    access: false,
    link: "/admin",
    icon: <AdminIcon />,
    position: "top",
  },
  {
    id: 5,
    component: Account,
    label: "Account",
    claim: "account",
    access: true,
    link: "/account",
    icon: <AccountIcon />,
    position: "bottom",
  },
];

const Main = React.memo((props) => {
  const classes = useStyles();
  return (
    <main className={classnames(classes.main)}>
      <div className={classes.fakeToolbar} />
      {props.content}
    </main>
  );
});

const Layout = (props) => {
  const classes = useStyles();
  const context = useContext(StateContext);
  return (
    <div className={classes.layout}>
      {props.drawer}
      <div
        className={classnames(classes.content, {
          [classes.mainShift]: context.state.isSidebarOpened,
        })}
      >
        <AppBar
          position="absolute"
          classes={{ root: classes.appcolor }}
          className={classnames(classes.head)}
        >
          <Toolbar className={classes.toolbar}>{props.toolbar}</Toolbar>
        </AppBar>
        <Main content={props.children} />
      </div>
    </div>
  );
};

export default Layout;
