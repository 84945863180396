import CostChart from "./costChart";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { ModalButton } from "components/form";
import PacketSummary from "./packetSummary";
import React from "react";
import Refresh from "@material-ui/icons/Refresh";
import VevolaColors from "vevolaColors";
import { formatMoney } from "components/format";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "components/icons";
import useApi from "components/api";

const useStyles = makeStyles(theme => ({

  taxation: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  main: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  detailpaper: {
    marginTop: theme.spacing(3),
  },

  table: {
    width: "100%",
    padding: theme.spacing(3),
    overflowX: "auto",
  },
  buttonContainer: {
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  editbutton: {
    "& path": {
      fill: "white",
    },
  },
  button: {
    color: "white",
  },
  closebutton: {
    "& path": { fill: "white" },
    backgroundColor: VevolaColors.warn,
    "&:hover": {
      backgroundColor: VevolaColors.warn,
    },
  },
  label: {
    textAlign: "left",
    textTransform: "none",
  },
  maintable: {
    width: "auto",
  },
}));

const BankPacketDetail = ({ packet }) => {
  const classes = useStyles();
  const [{ data: packet_detail, loading }, refetch] = useApi(
    "/api/nbi/packet/" + packet.id
  );

  let panden_title = "Panden";

  let taxation_titles = Array(2)
    .fill()
    .map((v, i) => `Taxatie (${i + 1})`);

  if (!loading) {
    panden_title += ` (${packet_detail?.properties.length})`;

    taxation_titles = ["year_1", "year_2", "year_3"]
      .map((key) => packet_detail?.taxations.map((t) => t[key]))
      .map((years) => Math.max(...years))
      .map((year) => (year !== 0 ? `Taxatie (${year})` : `Taxatie (n.n.b.)`));
  }

  if (packet.bank_name === "TOTAAL") {
    const divider = (packet, denominator) => {
      let newpacket = Object.fromEntries(
        Object.entries(packet).map(([k, v], i) => [
          k,
          k === "ltv" ? v : v / denominator,
        ])
      );
      return newpacket;
    };
    return (
      <Grid  container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <PacketSummary
            packet={packet}
            title={"Overzicht (Jaar)"}
            personal={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PacketSummary
            packet={divider(packet, 12)}
            title={"Overzicht (Maand)"}
            personal={true}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} sm={6} md={5} className={classes.summary}>
          <PacketSummary packet={packet} title={"Overzicht"} />
        </Grid>

        <Grid item xs={12}  sm={7} className={classes.costchart}>
          <CostChart packet={packet} loading={loading} />
        </Grid>

        <Grid item xs={12} className={classes.taxation}>
          <MaterialTable
            isLoading={loading}
            columns={[
              {
                title: "Adres",
                field: "address",
              },
              {
                title: "Plaats",
                field: "plaats",
              },
              {
                title: "Aanschaf waarde",
                field: "purchase",
                render: ({ purchase }) => formatMoney(purchase),
              },
              {
                title: taxation_titles[0],
                field: "value_1",
                render: ({ value_1: amount }) =>
                  amount ? formatMoney(amount) : null,
              },
              {
                title: taxation_titles[1],
                field: "value_2",
                render: ({ value_2: amount }) =>
                  amount ? formatMoney(amount) : null,
              },
              {
                title: taxation_titles[2],
                field: "value_3",
                render: ({ value_3: amount }) =>
                  amount ? formatMoney(amount) : null,
              },
            ]}
            data={packet_detail?.taxations}
            title="Taxaties (hoofdpand)"
            actions={[
              {
                icon: () => <Refresh />,
                tooltip: "Herlaad Data",
                isFreeAction: true,
                onClick: () => refetch(),
              },
            ]}
            options={{
              pageSize: 6,
              pageSizeOptions: [6, 16, 32],
              paging: true,
              emptyRowsWhenPaging: false,
              search: true,
            }}
            icons={tableIcons}
          />
        </Grid>
        <Grid item xs={12} className={classes.main}>
          <MaterialTable
            isLoading={loading}
            // onRowClick={(event, rowData) => {
            //   setEdit(rowData.id);
            //   console.log(rowData);
            // }}
            columns={[
              {
                title: "Adres",
                field: "address",
                render: ({ address, id }) => (
                  <ModalButton label={address} id={id} type={"property"} />
                ),
              },
              {
                title: "Plaats",
                field: "plaats",
              },
              {
                title: "Huurder",
                field: "renter_name",
                render: ({ renter_name, renter_id }) => (
                  <ModalButton
                    label={renter_name}
                    id={renter_id}
                    type={"renter"}
                  />
                ),
              },
              {
                title: "Huur",
                field: "rent",
                render: ({ rent }) => `€ ${rent}`,
              },
              {
                title: "Begin contract",
                field: "rent_contract_start",
                type: "date",
              },
              {
                title: "Eind contract",
                field: "rent_contract_end",
              },
              {
                title: "Borg",
                field: "borg",
                render: ({ borg }) => `€ ${borg ? borg : "-"}`,
              },
              {
                title: "Energie label",
                field: "energy_label",
              },
              {
                title: "CV Ketel",
                field: "cv_installation",
                type: "date",
              },
            ]}
            data={packet_detail?.properties}
            title={panden_title}
            actions={[
              {
                icon: () => <Refresh />,
                tooltip: "Herlaad Data",
                isFreeAction: true,
                onClick: () => refetch(),
              },
            ]}
            options={{
              pageSize: 8,
              pageSizeOptions: [8, 16, 32],
              paging: true,
              emptyRowsWhenPaging: false,
              search: true,
            }}
            icons={tableIcons}
          />
        </Grid>
      </Grid>
    );
  }
};

export default BankPacketDetail;
