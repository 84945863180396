import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core";
import { RenterContext } from ".";
import ListItem from "@material-ui/core/ListItem";
import { ListItemText } from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
    },
    green: {
      color: theme.palette.status.green,
    },
    red: {
      color: theme.palette.status.danger,
    },
    title: {
      fontSize: 14,
    },
    subheader: {
      fontWeight: 600,
      lineHeight: 32,
    },
    content: {
      paddingBottom: 0,
    },
  })
);

const OpenRentCard = () => {
  const context = React.useContext(RenterContext);
  const classes = useStyles();

  // Check if there are any current rents
  const activeRenter = context?.panden
    .map((item: any) => item["Einde verhuur per"] === null)
    .some((item: boolean) => item);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography className={classes.title} variant="h6" gutterBottom>
          {(context?.renter["Titel"] ? context?.renter["Titel"] + " " : "") +
            (context?.renter["Voorletters"]
              ? " " + context?.renter["Voorletters"] + " "
              : " ") +
            (context?.renter["Tussenvoegsel"]
              ? " " + context?.renter["Tussenvoegsel"] + " "
              : " ") +
            context?.renter["Naam"]}
        </Typography>
        <Typography
          className={classnames({
            [classes.green]: activeRenter,
            [classes.red]: !activeRenter,
          })}
          variant="h5"
          component="h2"
        >
          {activeRenter ? "Actieve Huurder" : "Geen Huurder"}
        </Typography>
        <Typography className={classes.title} variant="subtitle1">
          Geen subhuurder
        </Typography>
      </CardContent>
      <List
        component="ul"
        subheader={
          <ListSubheader color="inherit" component="div">
            Openstaande huur
          </ListSubheader>
        }
        className={classes.root}
      >
        <Divider />
        {context?.openRent.length > 0 ? (
          context?.openRent.map((item: any, index: number) => {
            return (
              <ListItem key={index} dense button divider>
                <ListItemText
                  classes={{ primary: classes.red }}
                  primary={"€ " + item.Expr6 + ",-"}
                  secondary={item.Expr1}
                  secondaryTypographyProps={{ color: "textPrimary" }}
                />
              </ListItem>
            );
          })
        ) : (
          <ListItem dense divider>
            <ListItemText secondary={"Geen openstaand huur"} />
          </ListItem>
        )}
      </List>
      <CardActions>
        <Button color="primary" size="small">
          Bewerken
        </Button>
        <Button color="primary" size="small">
          Financieel
        </Button>
      </CardActions>
    </Card>
  );
};

export default OpenRentCard;
