import React from "react";
import VevolaModal from "components/modal/vevolaModal";
import { VevolaAutocomplete, DatePicker } from "components/form";
import { produce } from "immer";
import { VevolaMoneyField } from "components/form/textfield";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Checkbox } from "components/form/checkbox";

import RenterEdit from "./renter/edit";
import useApi, { api } from "components/api";
import { useSnackbar } from "notistack";
import { AccordionActions, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordion: {
    "&$disabled": {
      backgroundColor: "#fff",
      opacity: 1,
    },
    "& .MuiCollapse-entered": {
      overflow: "hidden",
    },
  },
  disabled: {},
}));

const formatRenter = (renter) => {
  return (
    (renter["Titel"] ? renter["Titel"] + " " : "") +
    (renter["Voorletters"] ? " " + renter["Voorletters"] + " " : " ") +
    (renter["Tussenvoegsel"] ? " " + renter["Tussenvoegsel"] + " " : " ") +
    renter["Naam"]
  );
};

const formatProperty = (property) => {
  return property.ADRES + " " + property.plaats;
};

const initialState = {
  renter: null,
  newRenter: "new",
  validationError: {
    pand: false,
    renter: false,
  },
  rent: {
    "Ingang huur": null,
    "Maand huur": null,
    "geldig tot": null,
    "Bruto huur": null,
    "Service kosten": null,
    Stoffering: null,
    Gemeubileerd: false,
    Gestoffeerd: false,
    "Stookkosten en gas": false,
    "Elektra en water": false,
    Internet: false,
    "minimale huurverhoging": 0.025,
  },
};

const CheckInModal = (props) => {
  const [{ data, loading, error }] = useApi("/api/renters/get/1");

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = React.useState(initialState);
  const [expanded, setExpanded] = React.useState("panel1");

  const handleCheckIn = (value, prop) => {
    const newState = produce(state, (draftstate) => {
      draftstate[prop] = value;
    });
    setState(newState);
  };

  const handleRent = (value, prop) => {
    const newState = produce(state, (draftstate) => {
      draftstate.rent[prop] = value;
    });
    setState(newState);
  };

  const handleMoneyRent = (value, prop, total) => {
    const newState = produce(state, (draftstate) => {
      draftstate.rent[prop] = value;
      draftstate.rent["Maand huur"] = total;
    });
    setState(newState);
  };

  const handleLoadRent = (value) => {
    (async () => {
      const response = await api({
        url: "/api/rent/get/" + value + "&" + false,
      });

      const renters = await response.data[0];

      const newState = produce(state, (draftstate) => {
        draftstate.rent = renters;
      });
      setState(newState);
    })();
  };

  const handleChange = (panel) => (event, newExpanded) => {
    if (!state.rent.id) {
      const newState = produce(state, (draftstate) => {
        draftstate.validationError.pand = true;
      });
      setState(newState);
    } else if (panel === "panel3" && state.renter === null) {
      enqueueSnackbar("Vul de gegevens voor een huurder in", {
        variant: "error",
      });
    } else {
      const newState = produce(state, (draftstate) => {
        draftstate.validationError.pand = false;
      });
      setState(newState);
      setExpanded(panel);
    }
  };

  const classes = useStyles();

  const setRenter = (event) => {
    handleCheckIn(event, "renter");
  };

  const handleSubmit = (event) => {
    // Handle check-in procedure
    api({
      method: "post",
      url: "/api/rent/checkin",
      data: {
        renter: state.renter,
        rent: state.rent,
        options: data.options,
        newRenter: state.newRenter,
      },
    })
      .then((res) => {
        enqueueSnackbar("Huurder check-in gestart", {
          variant: "success",
        });
        props.handleClose();
      })
      .catch((err) => {
        console.error("An api error occured");
        console.error(JSON.stringify(err));
        enqueueSnackbar("An api error occured", {
          variant: "error",
        });
      });
  };

  if (loading) return <></>;
  else if (error) {
    enqueueSnackbar("Error: Couldnt create renter", {
      variant: "error",
    });
  }

  return (
    <VevolaModal
      open={true}
      onClose={props.handleClose}
      title={"Huurder inchecken"}
      loading={loading}
      error={error}
    >
      {!loading && !error && (
        <div className={classes.root}>
          <Accordion
            expanded={expanded === "panel1"}
            disabled={expanded !== "panel1"}
            classes={{ root: classes.accordion, disabled: classes.disabled }}
          >
            <AccordionSummary
              classes={{ root: classes.accordion, disabled: classes.disabled }}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography className={classes.heading}>Stap 1: Pand</Typography>
              <Typography className={classes.secondaryHeading}>
                Selecteer hier het vrijstaande pand waar de (nieuwe) huurder
                wordt ingecheckt
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={6}>
                  <VevolaAutocomplete
                    error={state.validationError.pand}
                    path={"/api/properties/vacant"}
                    format={formatProperty}
                    id={"id"}
                    label={"Pand"}
                    helperText={"Selecteer een pand"}
                    setValue={(event, newValue) => {
                      if (newValue) {
                        handleLoadRent(newValue.id);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Huurder</FormLabel>
                    <RadioGroup
                      aria-label="renter"
                      name="renter1"
                      value={state.newRenter}
                      onChange={(event) =>
                        handleCheckIn(event.target.value, "newRenter")
                      }
                    >
                      <FormControlLabel
                        color="default"
                        value="new"
                        control={<Radio color="primary" />}
                        label="Nieuwe Huurder"
                      />
                      <FormControlLabel
                        color="default"
                        value="existing"
                        control={<Radio color="primary" />}
                        label="Bestaande Huurder"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button disabled size="small">
                Vorige
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={handleChange("panel2")}
              >
                Volgende
              </Button>
            </AccordionActions>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            disabled={expanded !== "panel2"}
            classes={{ root: classes.accordion, disabled: classes.disabled }}
          >
            <AccordionSummary
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              classes={{ root: classes.accordion, disabled: classes.disabled }}
            >
              <Typography className={classes.heading}>
                Stap 2: Huurder
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Geef de gegevens op van de huurder
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {state.newRenter === "existing" ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} lg={6}>
                    <VevolaAutocomplete
                      path={"/api/renters/info"}
                      format={formatRenter}
                      id={"Id"}
                      label={"Huurder"}
                      helperText={"Selecteer een huurder"}
                      setValue={(event, newValue) => {
                        handleCheckIn(newValue.Id, "renter");
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <RenterEdit
                  renter={{
                    Loonstrookje: false,
                    Verhuurdersverklaring: false,
                    btw: false,
                  }}
                  options={data.options}
                  handleClose={() => null}
                  reload={() => null}
                  newrenter={true}
                  passRenter={setRenter}
                  noContext={true}
                />
              )}
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button size="small" onClick={handleChange("panel1")}>
                Vorige
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={handleChange("panel3")}
              >
                Volgende
              </Button>
            </AccordionActions>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            disabled={expanded !== "panel3"}
            classes={{ root: classes.accordion, disabled: classes.disabled }}
          >
            <AccordionSummary
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              classes={{ root: classes.accordion, disabled: classes.disabled }}
            >
              <Typography className={classes.heading}>
                Stap 3: Overige gegevens
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Vul benodigde gegevens aan zoals startdatum en huurprijs
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6} lg={6}>
                  {/* <Typography>
                  {JSON.stringify(state.rent || { data: null })}
                </Typography> */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={6}>
                      <DatePicker
                        label="Ingang huur"
                        value={state.rent["Ingang huur"]}
                        onChange={(e) => handleRent(e, "Ingang huur")}
                        margin="dense"
                      />{" "}
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <DatePicker
                        label="Geldig tot"
                        value={state.rent["geldig tot"]}
                        onChange={(e) => handleRent(e, "geldig tot")}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                  <VevolaMoneyField
                    rent={state.rent}
                    handleRent={handleMoneyRent}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <Checkbox
                    legend="Faciliteiten"
                    onChange={(e) =>
                      handleRent(e.target.checked, e.target.value)
                    }
                    rows={[
                      {
                        value: "Gemeubileerd",
                        checked: state.rent.Gemeubileerd,
                      },
                      { value: "Gestoffeerd", checked: state.rent.Gestoffeerd },
                      {
                        value: "Stookkosten en gas",
                        checked: state.rent["Stookkosten en gas"],
                      },
                      {
                        value: "Elektra en water",
                        checked: state.rent["Elektra en water"],
                      },
                      { value: "Internet", checked: state.rent.Internet },
                    ]}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button size="small" onClick={handleChange("panel2")}>
                Vorige
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Afronden
              </Button>
            </AccordionActions>
          </Accordion>
        </div>
      )}
    </VevolaModal>
  );
};

export default CheckInModal;
