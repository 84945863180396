import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Users from "./users";

const useStyles = makeStyles((theme) => ({
  users: {
    width: "auto",
    padding: theme.spacing(1),
  },
}));

const Admin = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.users}>
      <Users />
    </Paper>
  );
};

export default Admin;
