import React from "react";
import Moment from "moment";
import "moment/locale/nl";
import MaterialTable from "material-table";

import { makeStyles } from "@material-ui/core/styles";

import { tableIcons } from "../../../dashboard/pages/huurders/infoHuurders";
import DataField from "../dataField";
import { ModalContext } from "components/modal/context";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { RenterContext } from "components/modal/renter";
import OpenRentCard from "./openRentCard";

Moment.locale("nl");

const useStyles = makeStyles((theme) => ({
  left: {
    minWidth: 350,
    backgroundColor: theme.palette.background.paper,
  },
  dataField: {
    marginTop: theme.spacing(1),
  },
  comments: {
    marginBottom: theme.spacing(1),
  },
}));

const ViewModal = () => {
  const classes = useStyles();
  const { dispatch } = React.useContext(ModalContext);
  const { renter, panden } = React.useContext(RenterContext);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={3}>
          <OpenRentCard />
        </Grid>
        <Grid item xs={12} sm={12} lg={9}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} lg={12}>
              <h3>Gegevens (hoofdhuurder)</h3>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Grid container mt={1} spacing={1}>
                <DataField
                  grid={[12, 6, 6]}
                  name={"naam"}
                  value={
                    (renter["Titel"] ? renter["Titel"] + " " : "") +
                    (renter["Voorletters"]
                      ? " " + renter["Voorletters"] + " "
                      : " ") +
                    (renter["Tussenvoegsel"]
                      ? " " + renter["Tussenvoegsel"] + " "
                      : " ") +
                    renter["Naam"]
                  }
                />
                <DataField
                  grid={[12, 6, 6]}
                  name={"particulier / bedrijf"}
                  value={renter["particulier / bedrijf"]}
                />
                <DataField grid={[12, 6, 6]} name={"Id"} value={renter["Id"]} />
                <DataField
                  grid={[12, 6, 6]}
                  name={"Telefoon"}
                  value={renter["Telefoon nummer1"]}
                />
                <DataField
                  grid={[12, 6, 6]}
                  name={"Email"}
                  value={renter["email hoofd huurder"]}
                />
                <DataField
                  grid={[12, 6, 6]}
                  name={"Overig"}
                  value={renter["Overig"]}
                />

                <DataField
                  grid={[12, 6, 6]}
                  name={"Loonstrookje"}
                  value={renter["Loonstrookje"] ? "Ja" : "Nee"}
                />
                <DataField
                  grid={[12, 6, 6]}
                  name={"Verhuurdersverklaring"}
                  value={renter["Verhuurdersverklaring"] ? "Ja" : "Nee"}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={12} sm={12} lg={12}>
                  <h4>Nationaliteit</h4>
                </Grid>
                <DataField
                  name={"Geboorteland"}
                  value={renter["geboorteland hoofd"]}
                />
                <DataField
                  name={"Geboortedatum"}
                  value={
                    renter["geboortedatum hoofd"] &&
                    new Date(renter["geboortedatum hoofd"]).toLocaleDateString()
                  }
                />
                <DataField
                  name={"Sofie nummer"}
                  value={renter["Sofie nummer"]}
                />

                <Grid item xs={12} sm={12} lg={12}>
                  <h4>Identificatie</h4>
                </Grid>
                <DataField name={"Soort"} value={renter["soort"]} />
                <DataField name={"Land"} value={renter["land"]} />
                <DataField
                  name={"Nummer identificatie"}
                  value={renter["Nummer identificatie"]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} lg={12}>
          <h3>Subhuurder</h3>
          {renter["Sub huurder"] ? (
            <Grid container spacing={0}>
              <Grid item xs={12} sm={6} lg={6}>
                <Grid container spacing={0}>
                  <DataField
                    grid={[12, 6, 6]}
                    name={"naam"}
                    value={
                      (renter["Titel sub"] ? renter["Titel sub"] + " " : "") +
                      (renter["Sub voornaam"]
                        ? " " + renter["Sub voornaam"] + " "
                        : " ") +
                      renter["Sub huurder"]
                    }
                  />
                  <DataField
                    grid={[12, 6, 6]}
                    name={"Telefoon"}
                    value={renter["tel nummer sub huurer"]}
                  />
                  <DataField
                    grid={[12, 6, 6]}
                    name={"Email"}
                    value={renter["email sub huurder"]}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <Grid container mt={1} spacing={1}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <h4>Nationaliteit</h4>
                  </Grid>
                  <DataField
                    name={"Geboorteland"}
                    value={renter["geboorteland subhuurder"]}
                  />
                  <DataField
                    name={"Geboortedatum"}
                    value={new Date(
                      renter["geboortedatum subhuurder"]
                    ).toLocaleDateString()}
                  />

                  <Grid item xs={12} sm={12} lg={12}>
                    <h4>Identificatie</h4>
                  </Grid>
                  <DataField name={"Soort"} value={renter["sub id soort"]} />
                  <DataField name={"Land"} value={renter["sub id land"]} />
                  <DataField
                    name={"Nummer identificatie"}
                    value={renter["sub id nummer"]}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="subtitle2">Geen Subhuurder</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <h3>Huur (incl. historie)</h3>
          <MaterialTable
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            className={classes.table}
            onRowClick={(event, rowData) =>
              dispatch({
                type: "show",
                modalType: "property",
                modalProps: {
                  id: rowData["Pand"],
                  reload: () => false,
                  handleClose: () => dispatch({ type: "hide" }),
                },
              })
            }
            columns={[
              {
                title: "Adres",
                field: "Straatnaam",
                render: (row) => {
                  return (
                    row.Straatnaam +
                    " " +
                    row.nr +
                    " " +
                    (row["sub nummer"] ? row["sub nummer"] + " " : "") +
                    (row.toevoegsel ? row.toevoegsel : "")
                  );
                },
              },
              {
                title: "Huur",
                field: "Maand huur",
                render: (row) => `€ ${row["Maand huur"]}`,
              },
              {
                title: "Ingang huur",
                field: "Ingang huur",
                type: "datetime",
                defaultSort: "desc",
                render: (row) => Moment(row["Ingang huur"]).format("l"),
              },
              {
                title: "Einde verhuur",
                field: "Einde verhuur per",
                type: "datetime",
                render: (row) =>
                  row["Einde verhuur per"]
                    ? Moment(row["Einde verhuur per"]).format("l")
                    : "Geen einddatum",
              },
            ]}
            data={panden}
            options={{
              pageSize: 5,
              pageSizeOptions: [5],
              emptyRowsWhenPaging: false,
              toolbar: false,
              padding: "dense",
            }}
            icons={tableIcons}
            localization={{
              toolbar: { searchPlaceholder: "Zoeken" },
              body: {
                emptyDataSourceMessage: "Geen panden gekoppeld aan huurder",
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ViewModal;
