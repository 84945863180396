import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import SignOutButton from "dashboard/toolbar/signout";
import { StateContext } from "../index.js";
import logo from "logo.png";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
}));

const Toolbar = ({ structure, ...props }) => {
  const classes = useStyles();
  const context = useContext(StateContext);

  const [page, setPage] = useState("");

  useEffect(() => {
    const currentPage = structure.find(
      (x) => x.link === props.location.pathname
    );
    setPage(currentPage ? currentPage.label : "Dashboard");
  }, [structure, props.location]);

  return (
    <>
      {!context.state.isPermanent ? (
        <IconButton
          color="inherit"
          onClick={() => context.actions.toggleSidebar()}
          className={classes.headerMenuButton}
        >
          <MenuIcon
            classes={{
              root: classnames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      ) : null}
      <Typography variant="h6" weight="medium">
        {page}
      </Typography>
      <img src={logo} alt="logo" width="40px" />

      <SignOutButton display={{ xs: "none", md: "block" }} />
    </>
  );
};

export default withRouter(Toolbar);
