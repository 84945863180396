import React from "react";
import Chart from "react-apexcharts";

const PropertiesStatusChart = ({ data, handleChange }) => {
  const series_vacant = Object.entries(data).map(([k, v]) => {
    if (k === "upcomingVacant") {
      return v.length;
    } else if (k === "vacant") {
      return v.filter((x) => x.RENTER === 268).length;
    } else return 0;
  });

  const series_new_construction = Object.entries(data).map(([k, v]) => {
    if (k === "vacant") {
      return v.filter((x) => x.RENTER === 295).length;
    } else return 0;
  });

  const series_ending = Object.entries(data).map(([k, v]) => {
    if (k === "endingContract") {
      return v.filter((x) => !x.FINALDAYS).length;
    } else return 0;
  });

  const series_ending_final = Object.entries(data).map(([k, v]) => {
    if (k === "endingContract") {
      return v.filter((x) => x.FINALDAYS).length;
    } else return 0;
  });

  const options = {
    chart: {
      width: "80%",
      id: "panden-bar",
      stacked: true,
      toolbar: {
        show: false,
        offsetY: 24,
        offsetX: -15,
        tools: {
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
        autoSelected: "pan",
      },
      events: {
        dataPointSelection: function(event, chartContext, config) {
          handleChange(config.dataPointIndex + 1);
        },
      },
    },
    colors: ["#EC884D", "#4CC7EC", "#EC884D", "#4CC7EC"],
    yaxis: {
      title: {
        text: "Aantal panden",
      },
    },
    xaxis: {
      categories: ["Leegstand / Nieuwbouw", "Aflopend", "Opkomende Leegstand"],
    },
    legend: {
      show: false,
    },
  };

  return (
    <div style={{ width: "90%", margin: "0 auto" }}>
      <Chart
        options={options}
        series={[
          { name: "Leegstand", data: series_vacant },
          { name: "Nieuwbouw", data: series_new_construction },
          { name: "Aflopend", data: series_ending },
          { name: "Snel aflopend", data: series_ending_final },
        ]}
        type="bar"
        height={360}
        width={"100%"}
      />
    </div>
  );
};

export default PropertiesStatusChart;
