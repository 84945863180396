import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid } from "@material-ui/core";

import PasswordForgetForm from "./passwordForget";
import PasswordChangeForm from "./passwordChange";
import { withFirebase } from "components/firebase";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(3, 2)
  },
  propItem: {
    alignItems: "center",
    display: "flex",
    fontSize: 14,
    lineHeight: "20px",
    marginBottom: 12,
    minHeight: 24
  },
  label: {
    color: "rgba(0, 0, 0, 0.54)",
    display: "inline-block",
    width: 150
  }
}));

const AccountComponent = props => {
  const classes = useStyles();
  const user = props.firebase.auth().currentUser;
  const userProperties = [
    { name: "Naam", value: user.displayName },
    { name: "E-mail", value: user.email }
  ];
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="h3" gutterBottom={true}>
              Uw Gegevens
            </Typography>
            {userProperties.map((value, index) => {
              return (
                <div key={index} className={classes.propItem}>
                  <label className={classes.label}>{value.name}</label>
                  <span>{value.value}</span>
                </div>
              );
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="h3">
              Wachtwoord wijzigen
            </Typography>
            <PasswordChangeForm />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="h3">
              Wachtwoord herstellen
            </Typography>
            <PasswordForgetForm />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withFirebase(AccountComponent);
