import React, { useState, Fragment } from "react";
import VevolaModal from "components/modal/vevolaModal";
import { VevolaAutocomplete, DatePicker } from "components/form";
import { produce } from "immer";
import fileSaver from "file-saver";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CircularProgress from "@material-ui/core/CircularProgress";

import { api, apiWithTimeout } from "components/api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  genbutton: {
    color: "white",
    fontWeight: "bold",
  },
  leftbutton: {
    marginRight: theme.spacing(8),
  },
}));

const formatProperty = (property) => {
  return property.ADRES + " " + property.plaats;
};

const initialState = {
  validationError: {
    pand: false,
    endDate: false,
  },
  rent: null,
  renter: null,
  endDate: new Date(new Date().setHours(0, 0, 0, 0)),
  cause: null,
  endInspectionDownload: false,
};

const CheckOutForm = ({ open, onClose }) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [state, setState] = React.useState(initialState);

  const handleEndInspection = () => {
    const action = (key) => (
      <Fragment>
        <CircularProgress />
      </Fragment>
    );

    // Set a snackbar with an progress spinner
    enqueueSnackbar("Eindinspectieformulier wordt gedownload", {
      action,
      preventDuplicate: true,
    });

    // Fetch the contract but timeout after 5 seconds
    apiWithTimeout({
      method: "get",
      url: "/api/eindinspectie/get",
      responseType: "arraybuffer",
      ms: 5000,
    })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: res.headers["Content-Type"],
        });
        fileSaver.saveAs(blob, res.headers.name);
        // Change the state so we can finish the checkout
        const newState = produce(state, (draftstate) => {
          draftstate.endInspectionDownload = true;
        });
        setState(newState);
        // Close the loading snackbar
        closeSnackbar();
      })
      .catch((error) => {
        closeSnackbar();
        enqueueSnackbar("Contract genereren mislukt", {
          variant: "error",
        });
      });
  };

  const handleCheckOut = (value, prop) => {
    const newState = produce(state, (draftstate) => {
      draftstate[prop] = value;
      draftstate.validationError[prop] = false;
    });
    setState(newState);
  };

  const handleLoadRent = (value) => {
    (async () => {
      const response = await api({
        url: "/api/rent/get/" + value + "&" + true,
      });

      // Split response in order to change the rent property on checkout
      let renters = await response.data[0];
      const renter = (({ Voorletters, tussenvoegsel, Naam }) => ({
        Voorletters,
        tussenvoegsel,
        Naam,
      }))(renters);
      ["Voorletters", "tussenvoegsel", "Naam", "ID"].forEach(
        (e) => delete renters[e]
      );

      const newState = produce(state, (draftstate) => {
        draftstate.rent = renters;
        draftstate.renter = renter;
        draftstate.validationError.pand = false;
      });
      setState(newState);
    })();
  };

  const handleSubmit = (event) => {
    if (!state.rent || !state.endDate) {
      // Custom form checker to make sures fields are filled in, if not we change the state
      const newState = produce(state, (draftstate) => {
        draftstate.validationError.pand = !state.rent ? true : false;
        draftstate.validationError.endDate = !state.endDate ? true : false;
      });
      setState(newState);
    } else {
      // Handle check-out procedure if form validation passes
      api({
        method: "post",
        url: "/api/rent/checkout",
        data: { rent: state.rent, endDate: state.endDate, cause: state.cause },
      })
        .then((res) => {
          enqueueSnackbar("Huurder uitgecheckt", {
            variant: "success",
          });
          onClose();
        })
        .catch((err) => {
          console.error("An api error occured");
          console.error(JSON.stringify(err));
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        });
    }
  };

  return (
    <VevolaModal
      onClose={() => onClose()}
      title={"Huurder uitchecken"}
      actions={
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.leftbutton}
            onClick={() => handleEndInspection()}
            startIcon={<CloudDownloadIcon />}
          >
            eindinspectieformulier
          </Button>
          <Button onClick={() => onClose()} color="primary">
            Annuleer
          </Button>
          <Button
            disabled={!state.endInspectionDownload} // We are only able to submit after downloading end inspection form
            onClick={() => handleSubmit()}
            color="primary"
            variant="outlined"
          >
            Uitchecken
          </Button>
        </>
      }
    >
      <DialogContentText>
        Selecteer hier het pand voor het uitchecken.
      </DialogContentText>
      <VevolaAutocomplete
        error={state.validationError.pand}
        path={"/api/properties/occupied"}
        format={formatProperty}
        id={"id"}
        margin="dense"
        label={"Pand"}
        helperText={"Selecteer een pand"}
        setValue={(event, newValue) => {
          if (newValue) {
            handleLoadRent(newValue.id);
          }
        }}
      />
      {state.renter && (
        <>
          <Typography variant="body1">
            De volgende huurder wordt uitgecheckt:
          </Typography>
          <Typography variant="h6">
            {[
              state.renter.Voorletters,
              state.renter.tussenvoegsel,
              state.renter.Naam,
            ]
              .filter(Boolean)
              .join(" ")}
          </Typography>
        </>
      )}
      <TextField
        label="Reden opzegging"
        multiline
        rows={4}
        fullWidth
        placeholder="Korte reden"
        onChange={(e) => handleCheckOut(e.target.value, "cause")}
        margin="dense"
      />
      <DatePicker
        required
        error={state.validationError.endDate}
        label="Einde huur"
        value={state.endDate}
        onChange={(e) => handleCheckOut(e, "endDate")}
        margin="dense"
      />
    </VevolaModal>
  );
};

const RenterCheckOut = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={handleClickOpen}
        className={classes.genbutton}
      >
        Huurder Uitchecken
      </Button>
      {open && <CheckOutForm open={open} onClose={handleClose} />}
    </>
  );
};

export default RenterCheckOut;
