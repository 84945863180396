import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  checkers: {
    margin: theme.spacing(1, 0, 2, 0),
    minWidth: 120,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& label": {
      width: "100%",

      "& MuiTypography-root": {
        width: "100%",
      },
    },
  },
}));

const VevolaCheckbox = ({ legend, rows, onChange, className = null }) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {legend}
        <FormGroup row className={classes.checkers}>
          {rows.map((r) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={r.checked}
                  onChange={onChange}
                  value={r.value}
                  color="primary"
                />
              }
              key={r.value}
              label={r.field || r.value}
            />
          ))}
        </FormGroup>
      </FormLabel>
    </FormControl>
  );
};

export { VevolaCheckbox as Checkbox };
