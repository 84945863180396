import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import RenterCheckIn from "components/modal/renterCheckIn";
import RenterCheckOut from "components/modal/renterCheckOut";
import CheckInTable from "./checkinTable";

const CheckIn = (props) => {
  const [refetch, setRefetch] = useState(true);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <RenterCheckIn refetch={refetch} setRefetch={setRefetch} />
        </Grid>
        <Grid item xs={6}>
          <RenterCheckOut />
        </Grid>
        <Grid item xs={12}>
          <CheckInTable refetch={refetch} setRefetch={setRefetch} />
        </Grid>
      </Grid>
    </>
  );
};

export default CheckIn;
