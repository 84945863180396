import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { ModalContext } from "components/modal/context";

const useStyles = makeStyles((theme) => ({
  genbutton: {
    color: "white",
    fontWeight: "bold",
  },
}));

const RenterCheckIn = ({ refetch, setRefetch }) => {
  const classes = useStyles();
  const { dispatch } = React.useContext(ModalContext);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={() =>
          dispatch({
            type: "show",
            modalType: "checkIn",
            modalProps: {
              handleClose: () => {
                dispatch({ type: "hide" });
                setRefetch(true);
              },
            },
          })
        }
        className={classes.genbutton}
      >
        Huurder Inchecken
      </Button>
    </>
  );
};

export default RenterCheckIn;
