import { useEffect, useReducer } from "react";
import DefaultAxios from "axios";
import LRU from "lru-cache";
import Firebase from "components/firebase";
//Firebase token
const getToken = () =>
  new Promise((resolve, reject) =>
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) resolve(user.getIdToken(true));
      // set true for force refresh
      else console.error("Unauthenticated request");
    })
  );

let axiosInstance = DefaultAxios;

const api = async (config) => {
  const token = await getToken();
  if (config["headers"] === undefined) config["headers"] = {};
  config["headers"]["Authorization"] = "Bearer " + token;
  return axiosInstance(config);
};

const apiWithTimeout = async (config) => {
  // Create a promise that rejects in <ms> milliseconds
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject("Timed out in " + config.ms + "ms.");
    }, config.ms);
  });

  // Returns a race between our timeout and the passed in promise
  return Promise.race([api(config), timeout]);
};

export { api, apiWithTimeout };

//useAxios

const actions = {
  REQUEST_START: "REQUEST_START",
  REQUEST_END: "REQUEST_END",
};

const ssrPromises = [];

let cache = new LRU();

export function configure(options) {
  if (options.axios) {
    axiosInstance = options.axios;
  }

  if (options.cache) {
    cache = options.cache;
  }
}

export function loadCache(data) {
  cache.load(data);
}

export async function serializeCache() {
  await Promise.all(ssrPromises);

  ssrPromises.length = 0;

  return cache.dump();
}

async function cacheAdapter(config) {
  const cacheKey = JSON.stringify(config);
  const hit = cache.get(cacheKey);

  if (hit) {
    return hit;
  }

  delete config.adapter;
  const response = await axiosInstance(config);

  const responseForCache = { ...response };
  delete responseForCache.config;
  delete responseForCache.request;

  cache.set(cacheKey, responseForCache);

  return response;
}

function createInitialState(options) {
  return {
    loading: !options.manual,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case actions.REQUEST_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.REQUEST_END:
      return {
        ...state,
        loading: false,
        ...(action.error ? {} : { data: action.payload.data }),
        [action.error ? "error" : "response"]: action.payload,
      };
    default:
      return state;
  }
}

async function request(config, dispatch) {
  try {
    dispatch({ type: actions.REQUEST_START });
    //custom code
    const response = await api(config);
    dispatch({ type: actions.REQUEST_END, payload: response });
  } catch (err) {
    dispatch({ type: actions.REQUEST_END, payload: err, error: true });
  }
}

function executeRequestWithCache(config, dispatch) {
  request({ ...config, adapter: cacheAdapter }, dispatch);
}

function executeRequestWithoutCache(config, dispatch) {
  return request(config, dispatch);
}

export default function useApi(config, options = { manual: false }) {
  if (typeof config === "string") {
    config = {
      url: config,
    };
  }

  const [state, dispatch] = useReducer(reducer, createInitialState(options));

  if (typeof window === "undefined") {
    ssrPromises.push(axiosInstance({ ...config, adapter: cacheAdapter }));
  }

  const jsonconfig = JSON.stringify(config);
  useEffect(() => {
    if (!options.manual) {
      executeRequestWithCache(JSON.parse(jsonconfig), dispatch);
    }
  }, [jsonconfig, options.manual]);

  return [
    state,
    (configOverride, options = { useCache: false }) => {
      if (options.useCache) {
        return executeRequestWithCache(
          { ...config, ...configOverride },
          dispatch
        );
      }

      return executeRequestWithoutCache(
        { ...config, ...configOverride },
        dispatch
      );
    },
  ];
}
