import React from "react";
import Moment from "moment";
import "moment/locale/nl";
import { makeStyles } from "@material-ui/core/styles";

import DataField from "../dataField";
import { ModalContext } from "components/modal/context";

import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

Moment.locale("nl");

const useStyles = makeStyles((theme) => ({
  borghead: {
    margin: 0,
  },
  borgcell: {
    paddingLeft: 0,
  },
  color: {
    color: theme.palette.primary.main,
  },
}));

const ViewModal = ({ pand, options, reload }) => {
  const classes = useStyles();
  const { dispatch } = React.useContext(ModalContext);

  options.huurders = options.huurders.map((o) => {
    const type = {
      "1": "Mevrouw",
      "2": "De heer",
      "3": "Bedrijf",
      "4": "Stichting",
      "5": "",
    };
    o.value = [type[o.Titel], o.Voorletters, o.tussenvoegsel, o.Naam].join(" ");
    return o;
  });

  const faciliteiten = (pand) => {
    const options = [
      "Stookkosten en gas",
      "Elektra en water",
      "Internet",
      "Gemeubileerd",
      "Gestoffeerd",
    ];
    return options.reduce(
      (acc, cur) => (acc += pand[cur] ? (acc === "" ? cur : ", " + cur) : ""),
      ""
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={8} lg={8}>
        <Grid container mt={1} spacing={1} className={classes.left}>
          <Grid item xs={12} sm={12} lg={12}>
            <h3>Gegevens</h3>
          </Grid>
          <DataField
            name={"Pand"}
            value={[
              pand.pand.Straatnaam,
              pand.pand.nr,
              pand.pand.toevoegsel,
              pand.pand["sub nummer"],
            ].join(" ")}
          />
          <DataField
            name={"Ingang huur"}
            value={
              pand.pand["Ingang huur"]
                ? Moment(pand.pand["Ingang huur"]).format("l")
                : ""
            }
          />
          <DataField
            name={"Einde verhuur per"}
            value={
              pand.pand["Einde verhuur per"]
                ? Moment(pand.pand["Einde verhuur per"]).format("l")
                : ""
            }
          />
          <DataField
            onClick={() =>
              dispatch({
                type: "show",
                modalType: "renter",
                modalProps: {
                  id: pand.pand["Verhuurd aan"],
                  reload: reload,
                  handleClose: () => dispatch({ type: "hide" }),
                },
              })
            }
            name={"Verhuurd aan"}
            value={
              options.huurders.find(
                (option) => option.Id === pand.pand["Verhuurd aan"]
              ).value
            }
          />

          <DataField
            grid={[12, 6, 8]}
            name={"Faciliteiten"}
            value={faciliteiten(pand.pand)}
          />

          <DataField
            grid={[12, 12, 12]}
            name={"Opmerkingen"}
            value={pand.pand["opmerking"]}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <Grid container mt={1} spacing={1}>
          <Grid item xs={12} sm={12} lg={12}>
            <h3>Kosten</h3>
          </Grid>
          <DataField
            grid={[12, 12, 12]}
            name={"Maand huur (bruto)"}
            value={"€" + pand.pand["Bruto huur"]}
          />
          <DataField
            grid={[12, 12, 12]}
            name={"Servicekosten"}
            value={"€" + pand.pand["Service kosten"]}
          />
          <DataField
            grid={[12, 12, 12]}
            name={"Stofferingskosten"}
            value={"€" + pand.pand["Stoffering"]}
          />
          <DataField
            grid={[12, 12, 12]}
            name={"Maand huur (netto)"}
            value={"€" + pand.pand["Maand huur"]}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <h3 className={classes.borghead}>Borg</h3>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.borgcell} align={"left"}>
                Bedrag
              </TableCell>
              <TableCell className={classes.borgcell}>Datum</TableCell>
              <TableCell className={classes.borgcell}>Opmerking</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pand.borg.map((row, index) => {
              let datum = null;

              if (row["datum"] != null) {
                const d = new Date(row["datum"]);
                datum = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
              }
              return (
                <TableRow key={index}>
                  <TableCell className={classes.borgcell} scope="row">
                    € {row.bedrag}
                  </TableCell>
                  <TableCell className={classes.borgcell}> {datum} </TableCell>
                  <TableCell className={classes.borgcell}>
                    {" "}
                    {row.opmerking}{" "}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
export default ViewModal;
