import Chart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { formatMoney } from "components/format";
import useApi from "components/api";
// import BeatLoader from "react-spinners"

const RentChart = ({ data }) => {
  const categories = data.map((d) => d.plaats);
  const series = data.map((d) => d.SUM);

  const total = Math.round(series.reduce((a, b) => a + b, 0) * 100) / 100;

  const options = {
    chart: {
      id: "rent-bar",
      stacked: true,
      toolbar: {
        show: false,
        offsetY: 24,
        offsetX: -15,
        tools: {
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true,
        },
        autoSelected: "pan",
      },
    },
    title: {
      text: "Totale huur: " + formatMoney(total),
      offsetY: 10,
      offsetX: 14,
      margin: 50,
      style: {
        fontSize: "1.25rem",
        // fontFamily: "Roboto",
        fontWeight: 500,
        color: "black",
      },
    },
    colors: ["#EC884D", "#4CC7EC", "#fbde18", "#ef7963"],
    yaxis: {
      title: {
        text: "Totaal (€)",
      },
    },
    xaxis: {
      // type: "datetime",
      categories: categories,
      // labels: {
      //   style: {
      //     colors: ["#EC884D", "#4CC7EC", "#fbde18", "#ef7963"]
      //   }
      // },
    },
    // tooltip: {
    //   y: {
    //     formatter: (val) => formatMoney(val)
    //     },
    //   },
    // },
    legend: {
      position: "right",
      offsetY: 40,
    },
  };

  return (
    <Chart
      options={options}
      series={[{ name: "Bedrag", data: series }]}
      type="bar"
      height="420"
    />
  );
};

const TotalRentChart = (props) => {
  const [{ data, loading, error }] = useApi("/api/total_rent_region");
  if (error) {
    console.error(error);
    return "an error occured";
  }

  if (loading) {
    return <Paper {...props}></Paper>;
  }
  return (
    <Paper {...props}>
      <RentChart data={data} />
    </Paper>
  );
};

export default TotalRentChart;
