import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";

import React from "react";
import { formatMoney } from "components/format";

const PacketSummary = ({
  packet,
  title,
  personal = false,
  loading = false,
  style={},
}) => {
  const nbi_mapping = [
    {
      label: "Rente",
      field: "interest",
    },
    {
      label: "Aflossing",
      field: "repayment",
    },
    {
      label: "VVE",
      field: "vve",
    },
    // {
    //   label: "Verzekering",
    //   field: "insurance",
    // },
    {
      label: "Energie",
      field: "energy",
    },
    {
      label: "Water",
      field: "water",
    },
    {
      label: "Gemeente",
      field: "municipality_taxes",
    },
    // {
    //   label: "Overig",
    //   field: "other_costs",
    // },
    {
      label: "Netto besteedbaar",
      field: "net_spendable",
      final: true,
    },
  ];

  if (loading) {
    packet = {};
    nbi_mapping.forEach((x) => (packet[x.field] = 0));
  }
  const format = (key, div = 1) => formatMoney(packet[key] / div);

  return (
    <Paper style={{height:"100%"}}>
      <Toolbar>
        <Typography variant="h6">{title}</Typography>
      </Toolbar>
      <List dense>
        <ListItem key="Taxatie">
          <ListItemText>Taxatie:</ListItemText>
          <ListItemSecondaryAction>
            {format("current_taxation")}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem key="Lening">
          <ListItemText>Lening:</ListItemText>
          <ListItemSecondaryAction>
            {format("current_balance")}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem key="LTV">
          <ListItemText>LTV:</ListItemText>
          <ListItemSecondaryAction>
            <b>{packet["ltv"]}%</b>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem key="Huur">
          <ListItemText>Huur:</ListItemText>
          <ListItemSecondaryAction>
            <b> {format("rent")}</b>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        {nbi_mapping.map((r) => {
          if (r.final) {
            return (
              <React.Fragment key={r.label}>
                <Divider />
                <ListItem>
                  <ListItemText>{r.label}:</ListItemText>
                  <ListItemSecondaryAction>
                    <Typography variant="h6">
                      <b>{format(r.field)}</b>{" "}
                      {personal && (
                        <>
                          / <b>{format(r.field, 2)}</b>
                        </>
                      )}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              </React.Fragment>
            );
          } else
            return (
              <ListItem key={r.label}>
                <ListItemText>{r.label}:</ListItemText>
                <ListItemSecondaryAction>
                  {format(r.field)}
                </ListItemSecondaryAction>
              </ListItem>
            );
        })}
      </List>
    </Paper>
  );
};

export default PacketSummary;
