import React from "react";
import { ContextModal } from "./modals";

export function createCtx<StateType, ActionType>(
  reducer: React.Reducer<StateType, ActionType>,
  initialState: StateType
) {
  const defaultDispatch: React.Dispatch<ActionType> = () => initialState; // we never actually use this
  const ctx = React.createContext({
    state: initialState,
    dispatch: defaultDispatch, // just to mock out the dispatch type and make it not optioanl
  });
  function Provider(props: React.PropsWithChildren<{}>) {
    const [state, dispatch] = React.useReducer<
      React.Reducer<StateType, ActionType>
    >(reducer, initialState);
    return <ctx.Provider value={{ state, dispatch }} {...props} />;
  }
  return [ctx, Provider] as const;
}

// usage
const initialState = {
  modalType: "none",
  modalProps: {},
};
type AppState = typeof initialState;
type Action =
  | { type: "hide" }
  | { type: "show"; modalType: string; modalProps: any };

function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case "hide":
      return {
        modalType: "none",
        modalProps: {},
      };
    case "show":
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    default:
      return {
        ...state,
      };
  }
}
export const [ModalContext, ModalProvider] = createCtx(reducer, initialState);

// top level example usage
export function Modal() {
  return (
    <>
      <ContextModal />
    </>
  );
}
