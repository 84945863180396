import { Paper, Toolbar, Typography } from "@material-ui/core";

import Chart from "react-apexcharts";
import React from "react";
import { formatMoney } from "components/format";

const CostChart = ({ packet, loading, title = "Kosten", legend="right", style={} }) => {
  const cost_mapping = [
    // {
    //  label: "Huur",
    //  field: "rent"
    // },
    {
      label: "Rente",
      field: "interest",
    },
    {
      label: "Aflossing",
      field: "repayment",
    },
    {
      label: "VVE",
      field: "vve",
    },
    {
      label: "Verzekering",
      field: "insurance",
    },
    {
      label: "Energie",
      field: "energy",
    },
    {
      label: "Water",
      field: "water",
    },
    {
      label: "Gemeente",
      field: "municipality_taxes",
    },
    {
      label: "Overig",
      field: "other_costs",
    },
  ];

  console.log(packet)
  let data = !loading ? cost_mapping.map((x) => packet[x.field] || 0) : [];
  console.log(data)
  let options = {
    labels: cost_mapping.map((x) => x.label),
    tooltip: {
      y: {
        formatter: formatMoney,
      },
    },
    legend:{
       position: legend
    }    
  };

  return (
    <Paper style={{ marginBottom: 25, padding: 10,  height:"100%"}}>
      <Toolbar>
        <Typography variant="h6">{title}</Typography>
      </Toolbar>
      <Chart options={options} series={data} type="pie" height="372" />
    </Paper>
  );
};

export default CostChart;
