import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import VevolaModal from "components/modal/vevolaModal";
import Button from "@material-ui/core/Button";
import { api } from "components/api";
import VevolaColors from "vevolaColors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  buttons: {
    marginTop: "auto",
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  body: {
    minWidth: 350,
  },
  deletebutton: {
    color: "white",
    backgroundColor: VevolaColors.warn,
    "&:hover": {
      backgroundColor: VevolaColors.warn,
    },
  },
}));

const DeleteUser = ({ user, setUser, refetch }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const open = user != null;
  if (!open) return <></>;

  const modaltitle = "Verwijder " + user.displayName;

  const handleClose = () => {
    if (open) setUser(null);
  };

  const deleteUser = async () =>
    api({ method: "post", url: "/api/users/delete", data: user })
      .then((res) => {
        enqueueSnackbar("Gebruiker verwijdert", {
          variant: "success",
        });
        refetch();
        setUser(null);
      })
      .catch((err) => {
        console.error("An api error occured");
        console.error(JSON.stringify(err));
        console.error(err.response.data.message);
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });

  return (
    <VevolaModal onClose={handleClose} title={modaltitle}>
      <div className={classes.root}>
        <div className={classes.body}>
          Weet je zeker dat je <b>{user.displayName}</b> wilt verwijderen?
        </div>
        <div className={classes.buttons}>
          <Button color="primary" onClick={handleClose}>
            Annuleren
          </Button>
          <Button
            className={classes.deletebutton}
            variant="contained"
            onClick={deleteUser}
          >
            Verwijder
          </Button>
        </div>
      </div>
    </VevolaModal>
  );
};

export default DeleteUser;
