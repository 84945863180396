const customClaims = [
  {
    field: "admin",
    label: "Admin",
  },
  {
    field: "renters",
    label: "Huurders",
  },
  {
    field: "panden",
    label: "Panden",
  },
  {
    field: "nbi",
    label: "Netto Besteedbaar Inkomen",
  },
];

export default customClaims;
