import React from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";

import { api } from "components/api";
import { tableIcons } from "../huurders/infoHuurders";
import colors from "vevolaColors";

import CheckBoxBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBox from "@material-ui/icons/CheckBox";

const month = [];
month[0] = "jan";
month[1] = "feb";
month[2] = "mrt";
month[3] = "apr";
month[4] = "mei";
month[5] = "jun";
month[6] = "jul";
month[7] = "aug";
month[8] = "sep";
month[9] = "okt";
month[10] = "nov";
month[11] = "dec";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "transparent",
  },
}));

const PropertiesStatusTable = ({
  data,
  loading,
  sinds = "Sinds",
  dagen = "Aantal dagen",
  prolong = false,
  prolonged = undefined,
  setProlonged = undefined,
}) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const actions = prolong
    ? [
        (rowData) => ({
          icon: () =>
            prolonged.some((x) => x === rowData.verhuur_id) ? (
              <CheckBox />
            ) : (
              <CheckBoxBlank />
            ),
          disabled: prolonged.some((x) => x === rowData.verhuur_id),
          tooltip: "Verlengen",
          onClick: (event, rowData) => {
            confirm({
              title: "Contract verlengen",
              description:
                "Je staat op het punt " +
                rowData.ADRES +
                " te verlengen. Weet je het zeker?",
            }).then(() => {
              api({
                method: "post",
                url: "/api/properties/prolong",
                data: { id: rowData.verhuur_id },
              })
                .then((res) => {
                  // Set checkbox state to active for local confirmation (this will only be visible until rerender)
                  setProlonged((prolonged) => [
                    ...prolonged,
                    rowData.verhuur_id,
                  ]);
                  enqueueSnackbar("Contract verwerkt", {
                    variant: "success",
                  });
                })
                .catch((error) => {
                  enqueueSnackbar("Verwerken mislukt", {
                    variant: "error",
                  });
                });
            });
          },
        }),
      ]
    : [];

  return (
    <MaterialTable
      components={{
        Container: (props) => <Paper className={classes.table} {...props} />,
        Toolbar: (props) => <></>,
      }}
      loading={loading}
      columns={[
        {
          title: "Adres",
          field: "ADRES",
          cellStyle: {
            minWidth: 300,
          },
        },
        { title: "Stad", field: "plaats" },
        {
          title: sinds,
          field: "SINCE",
          render: (row) => {
            if (row["SINCE"]) {
              const d = new Date(row["SINCE"]);
              return `${d.getDate()} ${month[d.getMonth()]} ${d.getFullYear()}`;
            } else return "";
          },
        },
        {
          title: dagen,
          field: "DAYS",
        },
      ]}
      icons={tableIcons}
      data={data}
      options={{
        search: false,
        pageSize: 5,
        pageSizeOptions: [],
        emptyRowsWhenPaging: false,
        headerStyle: {
          backgroundColor: "transparent",
        },
        rowStyle: (rowData) => {
          if (rowData.FINALDAYS && prolong) {
            return { color: colors.warn };
          }
        },
      }}
      localization={{
        header: {
          actions: "",
        },
      }}
      actions={actions}
    />
  );
};

export const PropertiesEmptyTable = ({ data, loading }) => {
  const classes = useStyles();

  return (
    <MaterialTable
      components={{
        Container: (props) => <Paper className={classes.table} {...props} />,
        Toolbar: (props) => <></>,
      }}
      loading={loading}
      columns={[
        {
          title: "Adres",
          field: "ADRES",
          cellStyle: {
            minWidth: 300,
          },
        },
        { title: "Stad", field: "plaats" },
        {
          title: "Sinds (dagen)",
          field: "SINCE",
          cellStyle: {
            minWidth: 147,
          },
          render: (row) => {
            if (row["SINCE"]) {
              const d = new Date(row["SINCE"]);
              return (
                `${d.getDate()} ${month[d.getMonth()]} ${d.getFullYear()} (` +
                row["DAYS"] +
                ")"
              );
            } else return "";
          },
        },
        {
          title: "Soort",
          field: "RENTER",
          render: (row) => {
            if (row["RENTER"] === 268) return "Leegstaand";
            else return "Nieuwbouw";
          },
        },
        {
          title: "Gemiste huur",
          field: "RENTER",
          render: (row) => {
            if (row["RENTER"] === 268) {
              return `€ ${Math.round((row["RENT"] / 31) * row["DAYS"])}`;
            } else return "-";
          },
        },
      ]}
      icons={tableIcons}
      data={data}
      options={{
        search: false,
        pageSize: 5,
        pageSizeOptions: [],
        emptyRowsWhenPaging: false,
        headerStyle: {
          backgroundColor: "transparent",
        },
      }}
      localization={{
        header: {
          actions: "",
        },
      }}
    />
  );
};

export default PropertiesStatusTable;
