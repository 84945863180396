import React from "react";
import { ModalContext } from "./context";
import RenterModal from "./renter";
import PandModal from "./property";
import CheckInModal from "./checkIn";

const MODALS: Record<string, React.FC<any>> = {
  renter: RenterModal,
  property: PandModal,
  checkIn: CheckInModal,
  /* other modals */
};

export const ContextModal = () => {
  const { state } = React.useContext(ModalContext);

  if (state.modalType === "none") {
    return <></>;
  }

  const SpecificModal = MODALS[state.modalType];

  return (
    <>
      <SpecificModal {...state.modalProps} />
    </>
  );
};
