import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  readonly: {
    "& ::before": {
      display: "none",
    },
    "& ::after": {
      display: "none",
    },
    "& button": { display: "none" },
  },
}));

export const YearPicker = ({
  label,
  value,
  onChange,
  className = null,
  readonly = false,
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        margin="normal"
        views={["year"]}
        label={label}
        value={value}
        disabled={disabled}
        className={[className, readonly ? classes.readonly : ""].join(" ")}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  );
};

const VevolaDatePicker = ({
  label,
  value,
  onChange,
  className = null,
  readonly = false,
  disabled = false,
  margin = "normal",
  error = false,
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        error={error}
        margin={margin}
        id={label.replace(/\s+/g, "-").toLowerCase()}
        label={label}
        format="dd-MM-yyyy"
        value={value}
        invalidDateMessage="Ongeldig formaat (01-01-2021)"
        fullWidth={true}
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": { label },
        }}
        disabled={disabled}
        className={[className, readonly ? classes.readonly : ""].join(" ")}
        InputProps={{
          readOnly: readonly,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export { VevolaDatePicker as DatePicker };
