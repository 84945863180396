import React, { useState } from "react";
import { Refresh, tableIcons } from "components/icons";

import BankPacketDetail from "./bankPacketDetail";
import CostChart from "./costChart";
import DSCR from "./dscr";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import PacketSummary from "./packetSummary";
import TaxationChart from "./taxationChart";
import { formatMoney } from "components/format";
import useApi from "components/api";

const Table = ({ data, title, loading, refetch, packet, setPacket }) => {
  return (
    <MaterialTable
      isLoading={loading}
      // components={{
      //   Container: (props) => (
      //     <div class="nbi-table">
      //       <div {...props} />
      //     </div>
      //   ),
      // }}
      style={{ marginBottom: 25 }}
      columns={[
        {
          field: "bank_name",
          title: "Bank",
          defaultSort: "desc",
        },
        { field: "contract_no", title: "Lening" },
        {
          field: "date_start",
          title: "Aanvang",
          render: (row) => {
            if (row.date_start) {
              const d = new Date(row.date_start);
              return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
            } else return "";
          },
        },
        {
          field: "date_end",
          title: "Afloop",
          render: (row) => {
            if (row.date_end) {
              const d = new Date(row.date_end);
              return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
            } else return "";
          },
        },
        // {
        //   title: "Rente (jaar)",
        //   field: "interest_per_month",
        //   type: "currency",
        //   currencySetting: { currencyCode: "EUR" },
        // },
        {
          title: "Stand",
          field: "current_balance",
          render: ({ current_balance }) => formatMoney(current_balance),
        },
        {
          title: "Taxatie",
          field: "current_taxation",
          render: ({ current_taxation }) => formatMoney(current_taxation),
        },
        {
          title: "LTV",
          field: "ltv",
          render: ({ ltv }) => (ltv !== null ? ltv + "%" : ""),
        },
        {
          title: "Rente",
          field: "interest_rate",
          render: ({ interest_rate }) => {
            if (interest_rate)
              return `${Math.round(interest_rate * 1000) / 10}%`;
            else return null;
          },
        },
        {
          title: "Aflossing (maand)",
          field: "repayment_month",
          render: ({ repayment_month }) => formatMoney(repayment_month),
        },
        // {
        //   title: "Totaal",
        //   field: "total",
        //   type: "currency",
        //   currencySetting: { currencyCode: "EUR" },
        // },
        {
          title: "NBI (jaar)",
          field: "net_spendable",
          render: ({ net_spendable }) => formatMoney(net_spendable),
        },

        { title: "Panden", field: "properties" },
      ]}
      data={data}
      title={title}
      onRowClick={(event, rowData, togglePanel) => {
        setPacket(rowData);
        togglePanel();
      }}
      actions={[
        {
          icon: () => <Refresh />,
          tooltip: "Herlaad Data",
          isFreeAction: true,
          onClick: () => refetch(),
        },
      ]}
      detailPanel={(rowData) => {
        setPacket(rowData);
        return (
          <div
            style={{
              backgroundColor: "rgba(224, 224, 224, 1)",
              padding: "10px",
            }}
          >
            <BankPacketDetail packet={rowData} />
          </div>
        );
      }}
      options={{
        pageSize: 20,
        paging: false,
        emptyRowsWhenPaging: false,
        search: false,
        sorting: false,
        rowStyle: (rowData) => {
          return {
            backgroundColor: packet?.id === rowData.id ? "#EEE" : "#FFF",
          };
        },
      }}
      icons={tableIcons}
    />
  );
};

const BankPackets = (props) => {
  // const classes = useStyles();
  const [packet, setPacket] = useState(null);
  const [{ data, loading, error }, refetch] = useApi("/api/nbi/info");

  if (error) {
    console.error(error);
    return "an error occured";
  }

  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12}>
        <TaxationChart data={data?.packages} loading={loading} />
      </Grid>
      <Grid item xs={12} md={5}>
        <PacketSummary
          packet={data?.bankTotals["TOTAL"]}
          loading={loading}
          title={"Kosten totaal"}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <CostChart
          packet={data?.bankTotals["TOTAL"]}
          loading={loading}
          title="Kosten verdeling"
          legend="top"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DSCR
          packet={data?.bankTotals["ABN/AMRO"]}
          loading={loading}
          title="DSCR ABN AMRO"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DSCR packet={data?.bankTotals["ING"]} loading={loading} title="DSCR ING" />
      </Grid>
      <Grid item xs={12} md={6}>
        <DSCR packet={data?.bankTotals["Rabo Bank"]} loading={loading} title="DSCR Rabo Bank" />
      </Grid>

      <Grid item xs={12} md={6}>
        <DSCR packet={null} loading={true} title="DSCR NIBC" />
      </Grid>
      <Grid item xs={12}>
        <Table
          data={data?.packages}
          title={"Paketten"}
          loading={loading}
          refetch={refetch}
          packet={packet}
          setPacket={setPacket}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          data={data?.sold}
          title={"Verkochte Paketten"}
          loading={loading}
          refetch={refetch}
          packet={packet}
          setPacket={setPacket}
        />
      </Grid>
    </Grid>
  );
};

export default BankPackets;
