import React, { useState } from "react";
import produce from "immer";

import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";

import { withFirebase } from "components/firebase";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2, 0)
  }
}));

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null
};

const PasswordChangeForm = props => {
  const classes = useStyles();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmit = event => {
    const { passwordOne } = state;
    props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        setState(produce(state, () => INITIAL_STATE));
      })
      .catch(error => {
        setState(
          produce(state, draftstate => {
            draftstate.error = error;
          })
        );
      });
    event.preventDefault();
  };
  const onChange = event => {
    setState(
      produce(state, draftstate => {
        draftstate[event.target.name] = event.target.value;
      })
    );
  };

  const { passwordOne, passwordTwo, error } = state;
  const isInvalid = passwordOne !== passwordTwo || passwordOne === "";
  return (
    <form onSubmit={onSubmit}>
      <TextField
        name="passwordOne"
        fullWidth
        value={passwordOne}
        onChange={onChange}
        type="password"
        label="Nieuw Wachtwoord"
      />
      <TextField
        className={classes.button}
        name="passwordTwo"
        fullWidth
        value={passwordTwo}
        onChange={onChange}
        type="password"
        label="Bevestig Nieuw Wachtwoord"
      />
      <Button
        className={classes.button}
        variant="contained"
        disabled={isInvalid}
        type="submit"
      >
        Wijzig Wachtwoord
      </Button>
      {error && <p>{error.message}</p>}
    </form>
  );
};

export default withFirebase(PasswordChangeForm);
