import React from "react";
import Moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginLeft: -16,
    marginRight: 16,
  },
}));

const Comment = ({ comment, onDelete }) => {
  const classes = useStyles();

  const { createdAt, fullName } = comment;
  const textHtml = (
    <React.Fragment>
      {comment.text.split("\n").map((chunk, inx, arr) =>
        inx !== arr.length - 1 ? (
          <React.Fragment key={chunk + inx}>
            {chunk}
            <br />
          </React.Fragment>
        ) : (
          chunk
        )
      )}
    </React.Fragment>
  );

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar className={classes.avatar}>
          <Avatar>{fullName.substring(0, 1).toUpperCase()}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                component="span"
                variant="subtitle2"
                color="textPrimary"
              >
                {fullName}
              </Typography>{" "}
              <Typography
                component="span"
                variant="caption"
                color="textPrimary"
              >
                {Moment(createdAt.seconds * 1000).format("D MMMM YYYY, H:mm")}
              </Typography>
            </React.Fragment>
          }
          secondary={
            <>
              <Typography component="span" variant="body1" color="textPrimary">
                {textHtml}
              </Typography>
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton onClick={onDelete} edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default Comment;
