import { createMuiTheme, Theme } from "@material-ui/core/styles";
import colors from "./vevolaColors";

declare module "@material-ui/core/styles/createPalette" {
  interface StatusPaletteColorOptions {
    danger?: string;
    info?: string;
    green?: string;
  }
  interface StatusPaletteColor {
    danger: string;
    info: string;
    green: string;
  }
  interface PaletteOptions {
    success?: PaletteColorOptions;
    warning?: PaletteColorOptions;
    status?: StatusPaletteColorOptions;
  }
  interface Palette {
    success: PaletteColor;
    warning: PaletteColor;
    status: StatusPaletteColor;
  }
  interface TypeBackground {
    main: string;
    light: string;
  }
}

const vevolaTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      main: colors.grey1,
      light: colors.grey4,
    },
    status: {
      danger: colors.warn,
      info: colors.info,
      green: colors.green,
    },
  },
});

export default vevolaTheme;
