import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useSnackbar } from "notistack";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import LoginLayout from "./loginLayout";
import produce from "immer";
import { withFirebase } from "components/firebase";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

const LoginContainerBase = (props) => {
  const classes = useStyles();

  const [state, setState] = useState(INITIAL_STATE);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onSubmit = (event) => {
    const { email, password } = state;

    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        const nextState = produce(state, () => INITIAL_STATE);
        setState(nextState);
        closeSnackbar();
        enqueueSnackbar("Succesvol ingelogd", {
          variant: "success",
        });
        props.history.push(props.redirect);
      })
      .catch((error) => {
        const nextState = produce(state, (draftSate) => {
          draftSate.error = error;
        });
        setState(nextState);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });

    event.preventDefault();
  };

  const onChange = (event) => {
    const nextState = produce(state, (draftSate) => {
      draftSate[event.target.name] = event.target.value;
    });
    setState(nextState);
  };

  const isInvalid = state.password === "" || state.email === "";
  return (
    <LoginLayout onSubmit={onSubmit}>
      <TextField
        value={state.email}
        onChange={onChange}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Adres"
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextField
        value={state.password}
        onChange={onChange}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Wachtwoord"
        type="password"
        id="password"
      />
      <Button
        disabled={isInvalid}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Log In
      </Button>

      <Grid container>
        <Grid item xs>
          <Link href="#" variant="body2">
            Wachtwoord vergeten?
          </Link>
        </Grid>
      </Grid>
    </LoginLayout>
  );
};

const LoginContainer = compose(
  withRouter,
  withFirebase
)(LoginContainerBase);

export default LoginContainer;
