import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import useApi from "components/api";
import { forwardRef } from "react";
import useWindowResize from "components/hooks/useWindowResize";
import { ModalContext } from "components/modal/context";
import Moment from "moment";
import "moment/locale/nl";
import * as XLSX from "xlsx";

import Fab from "@material-ui/core/Fab";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Refresh from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import FolderCopy from "@material-ui/icons/FileCopy";
import { getProps, PdfHuurders } from "./pdfHuurders";

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },

  addrenter: {
    color: "white",
    margin: theme.spacing(1, 1, 0, 1),
  },
}));

const InfoHuurders = (props) => {
  const classes = useStyles();
  const { dispatch } = React.useContext(ModalContext);

  const [{ data, loading, error }, refetch] = useApi("/api/renters/info");
  const { height } = useWindowResize();

  if (error) {
    console.error(error);
    return "an error occured";
  }

  return (
    <>
      <MaterialTable
        isLoading={loading}
        columns={[
          {
            field: "Naam",
            title: "Naam",
            defaultSort: "asc",
            // First sort on active renters then on name.
            customSort: (a, b) => {
              if (!a.Naam || !b.Naam) {
                return b.active - a.active;
              }
              return b.active - a.active || a.Naam.localeCompare(b.Naam);
            },
            cellStyle: {
              minWidth: 320,
            },
            render: (row) => {
              const type = {
                1: "Mevrouw",
                2: "De heer",
                3: "Bedrijf",
                4: "Stichting",
                5: "",
              };
              return [
                type[row.Titel],
                row.Voorletters,
                row.tussenvoegsel,
                row.Naam,
              ].join(" ");
            },
          },
          { field: "Telefoon nummer1", title: "Telefoon" },
          { field: "particulier / bedrijf", title: "Type" },
          {
            field: "Huurder sinds",
            title: "Sinds",
            render: (row) => {
              if (row["Huurder sinds"]) {
                const d = new Date(row["Huurder sinds"]);
                return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
              } else return "";
            },
          },
          {
            field: "active",
            title: "Actieve huurder",
            type: "boolean",
          },
          { field: "Borg ontvangen", title: "Borg", type: "boolean" },
          { field: "Loonstrookje", title: "Loonstrookje", type: "boolean" },
          { field: "Sub huurder", title: "Subhuurder", type: "boolean" },
        ]}
        data={data}
        title="Huurders"
        onRowClick={(event, rowData) =>
          dispatch({
            type: "show",
            modalType: "renter",
            modalProps: {
              id: rowData.Id,
              reload: refetch,
              handleClose: () => dispatch({ type: "hide" }),
            },
          })
        }
        actions={[
          {
            icon: () => <Refresh />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => refetch(),
          },
          {
            icon: () => <PictureAsPdf />,
            tooltip: "Download huurder pdf",
            isFreeAction: true,
            onClick: async () => {
              const props = await getProps();
              const doc = <PdfHuurders data={props} />;
              const asPdf = pdf([]);
              asPdf.updateContainer(doc);
              const blob = await asPdf.toBlob();
              saveAs(
                blob,
                "huurderoverzicht-" +
                  Moment(new Date()).format("DD-MM-YYYY") +
                  ".pdf"
              );
            },
          },
          {
            icon: () => <FolderCopy />,
            tooltip: "Download huurder excel",
            isFreeAction: true,
            onClick: async () => {
              const props = await getProps();
              const worksheet = XLSX.utils.json_to_sheet(
                props.map((x) => ({
                  Naam: [x.Titel, x.Voorletters, x.tussenvoegsel, x.Naam]
                    .filter(Boolean)
                    .join(" "),
                  "Totaal huur": x["Maand huur"],
                  "Start contract per": Moment(x["Ingang huur"]).format(
                    "DD-MM-YYYY"
                  ),
                  "Looptijd (in dagen)": x.Looptijd,
                }))
              );
              const workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
              XLSX.writeFile(
                workbook,
                "huurderoverzicht-" +
                  Moment(new Date()).format("DD-MM-YYYY") +
                  ".xlsx"
              );
            },
          },
        ]}
        options={{
          pageSize: 50,
          pageSizeOptions: [10, 20, 50, 200],
          emptyRowsWhenPaging: false,
          maxBodyHeight: height - 128 - 80,
        }}
        icons={tableIcons}
        localization={{
          toolbar: { searchPlaceholder: "Zoeken" },
        }}
        // components={{
        //   Toolbar: props => (
        //     <div>
        //       <MTableToolbar {...props} />
        //       <div className={classes.buttons}>
        //         <Button
        //           className={classes.addrenter}
        //           color="primary"
        //           variant="contained"
        //         // onClick={saveRenter}
        //         >
        //           Toevoegen
        //         </Button>
        //       </div>
        //     </div>
        //   ),
        // }}
      />
      <div className={classes.buttons}>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.addrenter}
          onClick={() =>
            dispatch({
              type: "show",
              modalType: "renter",
              modalProps: {
                id: false,
                reload: refetch,
                handleClose: () => dispatch({ type: "hide" }),
              },
            })
          }
        >
          <AddIcon />
        </Fab>
      </div>
    </>
  );
};

export default InfoHuurders;
